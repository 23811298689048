import styled from "@emotion/styled"
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import { cvars } from "lib/theming/breakpoint"
import { Title1 } from "lib/ui/Typography/Typography"
import { Questions } from "./Questions"

export interface ContentfulFAQItem {
  id: string
  question: string
  answer: RenderRichTextData<ContentfulRichTextGatsbyReference>
}

export interface ContentfulFAQ {
  __typename: "ContentfulFaq"
  id: string
  title: string
  items: ContentfulFAQItem[]
}

interface FAQProps {
  faq: ContentfulFAQ
}

const Container = styled.div`
  padding: ${cvars.sectionFrameSpacing};
  display: flex;
  flex-flow: column;
`

const Spacing = styled.span`
  flex: 1;
`

export const FAQ = ({ faq: { title, items } }: FAQProps): JSX.Element => {
  return (
    <Container>
      {title && <Title1 as="h2">{title}</Title1>}
      {title && <Spacing />}
      <Questions items={items} />
    </Container>
  )
}
