import { VerticalSpacing } from "@einride/ui"

import styled from "@emotion/styled/macro"
import { ContentWrapper, HideAbove, HideBelow } from "components/shared/styles/Layout"
import { Title1 } from "lib/ui/Typography/Typography"
import { ThemeMode } from "types/common"
import { CardRow, ContentfulCardRow } from "../ImageCardRow/ImageCardRow"
import { ContentfulKpiRow, KPIRow } from "../KpiRow/KpiRow"

const RowsBlockWrapper = styled(ContentWrapper)`
  display: flex;
  padding: var(--section-frame-spacing);
  flex-direction: column;
`

const Title = styled(Title1)``

const ItemsWrapper = styled.div<{ numberOfItems: number }>``

export interface ContentfulRowsBlock {
  __typename: "ContentfulRowsBlock"
  id: string
  blockTitle: string
  rows: ContentfulKpiRow[] | ContentfulCardRow[]
}

interface RowProps {
  component: ContentfulRowsBlock
  themeMode?: ThemeMode
}

export const RowsBlock = ({ component, themeMode = "light" }: RowProps): JSX.Element => {
  const rows = component.rows.map((row: ContentfulKpiRow | ContentfulCardRow) => {
    const rnd = Math.floor(Math.random() * 1000)
    if (row.__typename === "ContentfulKpiRow") {
      return <KPIRow component={row} key={`${row.id}}-${rnd}`} themeMode={themeMode} />
    }
    return <CardRow component={row} key={`${row.id}}-${rnd}`} themeMode={themeMode} />
  })
  return (
    <RowsBlockWrapper>
      {component.blockTitle && <Title as="h2">{component.blockTitle}</Title>}
      <VerticalSpacing size="sm" />
      <ItemsWrapper numberOfItems={component.rows.length}>{rows}</ItemsWrapper>
      <HideAbove breakpoint={850}>
        <VerticalSpacing size="lg" />
        <VerticalSpacing size="lg" />
      </HideAbove>

      <HideBelow breakpoint={850}>
        <VerticalSpacing size="xl" />
        <VerticalSpacing size="xl" />
      </HideBelow>
    </RowsBlockWrapper>
  )
}
