import styled from "@emotion/styled"
import { ThemeProvider } from "lib/theming/ThemeProvider/ThemeProvider"
import { ReactNode } from "react"

interface SectionProps {
  children: ReactNode
  theme: "light" | "dark" | undefined
}

export const Section = ({ children, theme }: SectionProps): JSX.Element => {
  return (
    <ThemeProvider colorMode={theme ?? "light"}>
      <StyledSection>{children}</StyledSection>
    </ThemeProvider>
  )
}

const StyledSection = styled.section`
  background: ${({ theme }) => theme.colors.background.primary};
  color: ${({ theme }) => theme.colors.content.primary};
  a {
    color: ${({ theme }) => theme.colors.content.positive};
  }
`
