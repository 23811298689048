import { Icon, Paragraph, Title3 } from "@einride/ui"
import { IconName } from "@einride/ui/dist/cjs/components/content/Icon/Icon"
import styled from "@emotion/styled"
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
  renderRichText,
} from "gatsby-source-contentful/rich-text"

export const taxonomyTypes = {
  KEY_VALUE: "key-value",
  LIST: "list",
  "3COLS": "3-columns",
}

const ValidIcon = [
  "checkmark",
  "warning",
  "chevronDown",
  "chevronUp",
  "chevronRight",
  "chevronLeft",
  "xMark",
  "arrowUp",
  "arrowDown",
  "arrowRight",
  "arrowLeft",
  "arrowUpCircle",
  "arrowDownCircle",
  "bolt",
  "loupe",
  "plus",
  "ellipsis",
  "plusCircle",
  "minusCircle",
  "arrowClockwise",
  "arrowCounterclockwise",
  "electricTransport",
  "electricFleet",
  "electricAutonomousTransport",
  "intermodalTranport",
  "longhaulTransport",
  "maintenance",
  "remoteOperation",
  "swapping",
  "station",
  "cluster",
  "corridor",
  "routeOptimization",
  "batteryOptimization",
  "autonomousCharging",
  "ondemandCapacity",
  "electrificationAnalysis",
  "emissionAnalysis",
  "crossborder",
  "fossilFree",
  "resilientTransport",
  "circularEconomy",
]

export interface ContentfulTaxonomy {
  __typename: "ContentfulTaxonomy"
  id: string
  renderAs: string
  taxonomyGroup: TaxonomyGroup
  content: TaxonomyGroup[]
}

export interface TaxonomyProps {
  taxonomy: ContentfulTaxonomy
}

export type TaxonomyGroup = {
  id: string
  title: string
  items: TaxonomyItems[]
}

export type TaxonomyItems = {
  id: string
  highlighted: string
  icon?: IconName
  label: string
  value: RenderRichTextData<ContentfulRichTextGatsbyReference>
}

const Wrapper = styled.div<{ isList: boolean; isColumns: boolean }>`
  display: grid;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  padding: ${({ theme }) => 2 * theme.spacer}px;
  margin-bottom: ${({ theme }) => 2 * 6 * theme.spacer}px;
  @media ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: ${({ isList, isColumns }) =>
      isList && `repeat(${isColumns ? 3 : 2}, 1fr)`};
    padding: ${({ theme }) => 3 * theme.spacer}px;
    row-gap: 1rem;
    margin-bottom: ${({ theme }) => 2 * 8 * theme.spacer}px;
  }
`

const InlineIcon = styled(Icon)`
  margin-right: ${({ theme }) => 2 * theme.spacer}px;
  flex-shrink: 0;
`

const KeyValueWrapper = styled.div``

const KeyValueContent = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
  @media ${({ theme }) => theme.mediaQueries.md} {
    justify-content: center;
  }
  @media ${({ theme }) => theme.mediaQueries.belowMd} {
    ${InlineIcon} {
      margin-right: 0.5rem;
    }
  }
`

const Label = styled(Title3)`
  align-self: baseline;
  color: ${({ theme }) => theme.colors.content.secondary};
  font-size: 1.125rem;
  font-feature-settings: "zero";
  font-variant-numeric: slashed-zero;
  -webkit-font-feature-settings: "zero" !important;
  @media ${({ theme }) => theme.mediaQueries.md} {
    font-size: x-large;
  }
`
const Value = styled.div<{ highlighted: string; isList: boolean }>`
  align-self: baseline;
  display: flex;
  p {
    color: ${({ theme, highlighted, isList }) =>
      // eslint-disable-next-line no-nested-ternary
      highlighted === "#000000"
        ? isList
          ? theme.colors.content.secondary
          : theme.colors.content.primary
        : `${highlighted}`};
    font-size: 1.125rem;
    font-feature-settings: "zero";
    font-variant-numeric: slashed-zero;
    -webkit-font-feature-settings: "zero" !important;
    @media ${({ theme }) => theme.mediaQueries.md} {
      font-size: ${({ isList }) => (isList ? "1.1rem" : "x-large")};
    }
  }
`

const ListWrapper = styled.div`
  grid-template-columns: 1fr 1fr;
  @media ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: 1fr 1fr;
  }
`

const ListContent = styled.div`
  display: flex;
  margin: 0.5rem 0;
  @media ${({ theme }) => theme.mediaQueries.md} {
    margin: 1rem 0;
  }
`

const ListTitle = styled.h2`
  color: ${({ theme }) => theme.colors.content.primary};
  margin-bottom: 1.5rem;
  @media ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 2.5rem;
  }
`
const ListKeys = styled.h4`
  color: ${({ theme }) => theme.colors.content.primary};
`

const ListBody = styled.div`
  line-height: calc(4 / 3);
`

export const Taxonomy = ({ taxonomy }: TaxonomyProps): JSX.Element => {
  const { content, renderAs } = taxonomy

  const isValidIcon = (iconValue: string): iconValue is IconName => {
    return ValidIcon.includes(iconValue)
  }

  if (renderAs === taxonomyTypes.KEY_VALUE) {
    return (
      <Wrapper isList={false} isColumns={false}>
        {content.map((group: TaxonomyGroup) => {
          return (
            <KeyValueWrapper key={`key-value-${group.id}`}>
              {group.items.map((item: TaxonomyItems) => {
                return (
                  <KeyValueContent key={item.id}>
                    <Label>
                      {item.icon && isValidIcon(item.icon) && <InlineIcon name={item.icon} />}
                      {item.label}
                    </Label>
                    {item.value && (
                      <Value isList={false} highlighted={item.highlighted}>
                        <Title3>{renderRichText(item.value)}</Title3>
                      </Value>
                    )}
                  </KeyValueContent>
                )
              })}
            </KeyValueWrapper>
          )
        })}
      </Wrapper>
    )
  }

  return (
    <Wrapper isList isColumns={renderAs === taxonomyTypes["3COLS"]}>
      {content.map((group: TaxonomyGroup) => {
        return (
          <ListWrapper key={`list-${group.id}`}>
            <ListTitle>{group.title}</ListTitle>
            {group.items.map((item) => {
              return (
                <ListContent key={item.id}>
                  {item.icon && <InlineIcon name={item.icon} />}
                  <ListBody>
                    <ListKeys>{item.label}</ListKeys>
                    {item.value && (
                      <Value highlighted={item.highlighted} isList>
                        <Paragraph as="span">{renderRichText(item.value)}</Paragraph>
                      </Value>
                    )}
                  </ListBody>
                </ListContent>
              )
            })}
          </ListWrapper>
        )
      })}
    </Wrapper>
  )
}
