import { Icon, PrimaryButton, VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled/macro"
import { BackgroundColor, ContentfulContent } from "components/contentful-ui/Content/Content"
import { Media } from "components/contentful-ui/Media/Media"
import { RichText } from "components/contentful-ui/RichText/RichText"
import { Link } from "components/ui/Link/Link"
import { cvars } from "lib/theming/breakpoint"
import { Paragraph, Title1 } from "lib/ui/Typography/Typography"

export interface MobileViewProps {
  className?: string
  content: ContentfulContent
}

export const MobileView = ({ className, content }: MobileViewProps): JSX.Element => {
  const {
    backgroundColor,
    backgroundMedia,
    foregroundMedia,
    link,
    linkStyle,
    mobileBackgroundMedia,
    mobileForegroundMedia,
    text,
    title,
    secondaryTitle,
    foregroundMediaFit,
  } = content

  const renderLink = (): JSX.Element | undefined => {
    if (!link) {
      return undefined
    }
    if (linkStyle === "primary") {
      return (
        <Link to={link.url}>
          <CTAButton rightIcon={<Icon name="arrowRight" />}>{link.text}</CTAButton>
        </Link>
      )
    }
    return (
      <Link to={link.url}>
        <LinkText>{link.text}</LinkText>
      </Link>
    )
  }

  return (
    <Wrapper backgroundColor={backgroundColor} className={className}>
      <BackgroundMedia media={mobileBackgroundMedia ?? backgroundMedia} />
      <Content imageLayout={foregroundMediaFit}>
        <Up foregroundMedia={!!(mobileForegroundMedia || foregroundMedia)}>
          <div>
            {title && <Title as="h2">{title}</Title>}
            {secondaryTitle && <SubTitle as="h3">{secondaryTitle}</SubTitle>}
            <VerticalSpacing size="lg" />
          </div>

          <div>
            {text && (
              <ContentText>
                <RichText body={text} />
              </ContentText>
            )}
            {text && link && <VerticalSpacing />}
            {link && (
              <>
                {renderLink()}

                <VerticalSpacing size="sm" />
              </>
            )}
          </div>
        </Up>

        <>
          {(foregroundMedia || mobileForegroundMedia) && (
            <Down imageLayout={foregroundMediaFit}>
              <Media media={mobileForegroundMedia ?? foregroundMedia} />
            </Down>
          )}
        </>
      </Content>
    </Wrapper>
  )
}

const BackgroundMedia = styled(Media)`
  grid-area: 1/1;
  object-fit: cover;
`

const Content = styled.div<{
  imageLayout: string | undefined
}>`
  min-height: 94vh;
  grid-area: 1/1;
  padding: ${cvars.sectionFrameSpacing};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${({ imageLayout }) =>
    imageLayout === "cover" ? "flex-start" : "space-between"};
  gap: ${cvars.gridGutter};
`

const Up = styled.div<{ foregroundMedia: boolean }>`
  grid-row: 1;
  ${({ foregroundMedia }) =>
    !foregroundMedia &&
    `
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;
      height: 100%;
    `}
`

const Down = styled.div<{ imageLayout: string | undefined }>`
  ${({ imageLayout }) => imageLayout === "cover" && "flex-grow: 1;"}
`

const Title = styled(Title1)``

const SubTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.content.secondary};
`

const ContentText = styled.div``

const LinkText = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.content.positive};
  text-decoration: underline;
  margin: 0;
`

const Wrapper = styled.div<{
  backgroundColor: BackgroundColor | undefined
}>`
  ${({ backgroundColor, theme }) =>
    backgroundColor === "background secondary" &&
    `background: ${theme.colors.background.secondary}`};
  display: grid;
  position: relative;
`

const CTAButton = styled(PrimaryButton)`
  --einride-ui-span: 3;
  margin-top: 0.5rem;

  @media ${({ theme }) => theme.mediaQueries.md} {
    --einride-ui-span: 4;
  }

  min-width: calc(
    (
        (100vw - calc(var(--einride-ui-grid-columns) + 1) * var(--einride-ui-grid-gap)) /
          var(--einride-ui-grid-columns) + var(--einride-ui-grid-gap)
      ) * var(--einride-ui-span) - var(--einride-ui-grid-gap) - var(--einride-ui-grid-gap)
  );
`
