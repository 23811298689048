import { TextInput, Textarea, VerticalLayout } from "@einride/ui"
import styled from "@emotion/styled"
import * as Sentry from "@sentry/gatsby"
import { suite } from "components/fragments/ShipWithUsForm/suite"
import { StyledWithIconButton } from "components/ui/StyledWithIconButton/StyledWithIconButton"
import { useSiteMetadata } from "hooks/useSiteMetadata"
import { Paragraph } from "lib/ui/Typography/Typography"
import { ChangeEvent, FormEvent, useState } from "react"
import { pushLeadFormData } from "utils/tracking"
import { SuiteResult } from "vest"
import { FormGdpr } from "../FormGdpr/FormGdpr"

const submitShipWithUsForm = async (url: string, form: unknown): Promise<void> => {
  const req = new Request(url, {
    method: "POST",
    body: JSON.stringify(form),
  })
  const res = await fetch(req)
  if (!res.ok) {
    throw new Error(`statusText: ${res.statusText}, status: ${res.status}`)
  }
}

const Form = styled.form`
  width: 100%;
  display: block;
`

const StyledVerticalLayout = styled(VerticalLayout)`
  margin-bottom: ${({ theme }) => 2 * theme.spacer}px;
  align-items: stretch;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 0;
  }
`

const TextareaButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Asterisk = styled.span`
  color: ${({ theme }) => theme.colors.content.negative};
`

interface ShipWithUsFormProps {
  leadSource?: string | undefined
}

export const ShipWithUsForm = ({
  leadSource = "Ship with us form",
}: ShipWithUsFormProps): JSX.Element => {
  const { endpoint } = useSiteMetadata()
  const { lead } = endpoint

  const [fullName, setFullName] = useState("")
  const [company, setCompany] = useState("")
  const [title, setTitle] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [country, setCountry] = useState("")
  const [message, setMessage] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)
  const [errors, setErrors] = useState<SuiteResult>(() => suite.get())
  const [hasInteracted, setHasInteracted] = useState(false)

  const clearForm = (): void => {
    setFullName("")
    setCompany("")
    setTitle("")
    setEmail("")
    setPhone("")
    setCountry("")
    setMessage("")
  }

  const handleJobTitleChange = (): void => {
    if (!hasInteracted) {
      pushLeadFormData("begin_lead_form", { company, country }, leadSource)
    }
    setHasInteracted(true)
  }

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()

    const suiteRes = suite({ fullName, company, title, email, message })
    if (suiteRes.hasErrors()) {
      setErrors(suiteRes)
      pushLeadFormData("lead_form_submission_failed", { country, company }, leadSource)
      return
    }

    const gclid = localStorage.getItem("gclid")
    const gclidValue = gclid ? JSON.parse(gclid).value : null
    const form = {
      full_name: fullName,
      company,
      title,
      email,
      phone,
      country,
      message,
      lead_source: leadSource,
      gclid: gclidValue,
    }

    try {
      await submitShipWithUsForm(`${process.env.GATSBY_API_HOST}${lead}`, form)
      setSubmitted(true)
      setError(false)
      pushLeadFormData("submit_lead_form", form, leadSource)
      clearForm()
    } catch (err) {
      setSubmitted(false)
      setError(true)
      pushLeadFormData("lead_form_submission_failed", form, leadSource)
      Sentry.captureException(err)
    }
  }

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <StyledVerticalLayout>
        <TextInput
          label={
            <>
              Full name <Asterisk>*</Asterisk>
            </>
          }
          message={errors.getErrors("fullName")[0]}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFullName(e.target.value)
          }}
          placeholder="Full name"
          status={errors.hasErrors("fullName") ? "fail" : "neutral"}
          type="text"
          value={fullName}
        />
        <TextInput
          label={
            <>
              Company <Asterisk>*</Asterisk>
            </>
          }
          message={errors.getErrors("company")[0]}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setCompany(e.target.value)
          }}
          placeholder="Company"
          status={errors.hasErrors("company") ? "fail" : "neutral"}
          type="text"
          value={company}
        />
        <TextInput
          label={
            <>
              Job title <Asterisk>*</Asterisk>
            </>
          }
          message={errors.getErrors("title")[0]}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleJobTitleChange()
            setTitle(e.target.value)
          }}
          placeholder="Job title"
          status={errors.hasErrors("title") ? "fail" : "neutral"}
          type="text"
          value={title}
        />
        <TextInput
          label={
            <>
              Work email <Asterisk>*</Asterisk>
            </>
          }
          message={errors.getErrors("email")[0]}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value)
          }}
          placeholder="Work email"
          status={errors.hasErrors("email") ? "fail" : "neutral"}
          type="email"
          value={email}
        />
        <TextInput
          label="Phone number"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setPhone(e.target.value)
          }}
          type="phone"
          value={phone}
        />
        <TextInput
          label="Country"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setHasInteracted(true)
            setCountry(e.target.value)
          }}
          type="text"
          value={country}
        />
        <Textarea
          aria-invalid={errors.hasErrors("message") ? "true" : "false"}
          label={
            <>
              My message <Asterisk>*</Asterisk>
            </>
          }
          labelProps={{ style: { display: "block" } }}
          message={errors.getErrors("message")[0]}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setMessage(e.target.value)
          }}
          status={errors.hasErrors("message") ? "fail" : "neutral"}
          value={message}
          wrapperProps={{ style: { display: "flex", flexGrow: 1, flexDirection: "column" } }}
        />
        <TextareaButtonWrapper>
          <FormGdpr />
          {submitted ? (
            <Paragraph>Submitted!</Paragraph>
          ) : (
            <StyledWithIconButton>Submit</StyledWithIconButton>
          )}
          {error && <Paragraph>Unfortunately, the contact form could not be submitted.</Paragraph>}
        </TextareaButtonWrapper>
      </StyledVerticalLayout>
    </Form>
  )
}
