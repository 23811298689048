import { TextInput, VerticalLayout } from "@einride/ui"
import styled from "@emotion/styled"
import * as Sentry from "@sentry/gatsby"
import { suite } from "components/fragments/JoinSagaForm/suite"
import { StyledWithIconButton } from "components/ui/StyledWithIconButton/StyledWithIconButton"
import { useSiteMetadata } from "hooks/useSiteMetadata"
import { Paragraph } from "lib/ui/Typography/Typography"
import { ChangeEvent, FormEvent, useState } from "react"
import { pushLeadFormData } from "utils/tracking"
import { SuiteResult } from "vest"
import { FormGdpr } from "../FormGdpr/FormGdpr"

const submitJoinSagaForm = async (url: string, form: unknown): Promise<void> => {
  const req = new Request(url, {
    method: "POST",
    body: JSON.stringify(form),
  })
  const res = await fetch(req)
  if (!res.ok) {
    throw new Error(`statusText: ${res.statusText}, status: ${res.status}`)
  }
}

const Form = styled.form`
  width: 100%;
  display: block;
`

const StyledVerticalLayout = styled(VerticalLayout)`
  margin-bottom: ${({ theme }) => 2 * theme.spacer}px;
  align-items: stretch;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 0;
  }
`

const TextareaButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Asterisk = styled.span`
  color: ${({ theme }) => theme.colors.content.negative};
`

interface JoinSagaFormProps {
  leadSource?: string | undefined
}

export const JoinSagaForm = ({
  leadSource = "Saga priority list form",
}: JoinSagaFormProps): JSX.Element => {
  const { endpoint } = useSiteMetadata()
  const { contact } = endpoint

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)
  const [errors, setErrors] = useState<SuiteResult>(() => suite.get())

  const clearForm = (): void => {
    setName("")
    setEmail("")
    setCompany("")
  }

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()

    const suiteRes = suite({ name, email, company })
    setErrors(suiteRes)
    if (suiteRes.hasErrors()) {
      pushLeadFormData("lead_form_submission_failed", { company }, leadSource)
      return
    }

    const gclid = localStorage.getItem("gclid")
    const gclidValue = gclid ? JSON.parse(gclid).value : null
    const form = {
      full_name: name,
      topic: "general_or_other",
      email,
      company,
      message: "Join Saga request, sent from www.einride.tech/join-saga",
      lead_source: leadSource,
      gclid: gclidValue,
    }

    try {
      await submitJoinSagaForm(`${process.env.GATSBY_API_HOST}${contact}`, form)
      setSubmitted(true)
      setError(false)
      pushLeadFormData("submit_lead_form", form, leadSource)
      clearForm()
    } catch (err) {
      setSubmitted(false)
      setError(true)
      pushLeadFormData("lead_form_submission_failed", form, leadSource)
      Sentry.captureException(err)
    }
  }

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <StyledVerticalLayout>
        <TextInput
          label={
            <>
              Name <Asterisk>*</Asterisk>
            </>
          }
          message={errors.getErrors("name")[0]}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setName(e.target.value)
          }}
          placeholder="Name"
          status={errors.hasErrors("name") ? "fail" : "neutral"}
          type="text"
          value={name}
        />
        <TextInput
          label={
            <>
              Email <Asterisk>*</Asterisk>
            </>
          }
          message={errors.getErrors("email")[0]}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value)
          }}
          placeholder="Email"
          status={errors.hasErrors("email") ? "fail" : "neutral"}
          type="email"
          value={email}
        />
        <TextInput
          label={
            <>
              Company <Asterisk>*</Asterisk>
            </>
          }
          message={errors.getErrors("company")[0]}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setCompany(e.target.value)
          }}
          placeholder="Company"
          status={errors.hasErrors("company") ? "fail" : "neutral"}
          type="text"
          value={company}
        />
        <TextareaButtonWrapper>
          <FormGdpr />
          {submitted ? (
            <Paragraph>Submitted!</Paragraph>
          ) : (
            <StyledWithIconButton>Sign up</StyledWithIconButton>
          )}
          {error && (
            <Paragraph>Unfortunately, the Join Saga form could not be submitted.</Paragraph>
          )}
        </TextareaButtonWrapper>
      </StyledVerticalLayout>
    </Form>
  )
}
