import { create, enforce, only, test } from "vest"

export const suite = create(
  (
    data: {
      email: string
    },
    fieldName?: string,
  ) => {
    only(fieldName)

    test("email", "Email is required", () => {
      enforce(data.email).isNotBlank()
    })

    test("email", "Email is not valid", () => {
      enforce(data.email).matches(/\S+@\S+\.\S+/)
    })
  },
)
