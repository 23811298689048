import { VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled/macro"
import { cvars } from "lib/theming/breakpoint"
import { ThemeMode } from "types/common"
import { PageComponents } from "types/contentful"
import { Mapper } from "utils/componentMapper"

const Wrapper = styled.div`
  padding: ${cvars.sectionFrameSpacing};

  @media ${({ theme }) => theme.mediaQueries.md} {
    display: grid;
    gap: ${cvars.gridGutter};
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
`

const Column = styled.div`
  & > * {
    padding: 0 !important;
    margin: 0 !important;
  }
`

type ContentfulContentLrProps = {
  leftColumn: PageComponents[]
  rightColumn: PageComponents[]
  theme: ThemeMode
}

export const TwoColumnContent = ({
  leftColumn,
  rightColumn,
  theme,
}: ContentfulContentLrProps): JSX.Element => {
  return (
    <>
      <Wrapper>
        <Column>{Mapper(leftColumn, theme)}</Column>
        <Column>{Mapper(rightColumn, theme)}</Column>
      </Wrapper>
      <VerticalSpacing size="xl" />
      <VerticalSpacing size="xl" />
    </>
  )
}

export interface ContentfulTwoColumnContent {
  __typename: "ContentfulTwoColumnContent"
  id: string
  leftColumn: PageComponents[]
  rightColumn: PageComponents[]
}
