import styled from "@emotion/styled"
import { RichText } from "components/contentful-ui/RichText/RichText"
import { ContentfulRichText } from "types/contentful"

const Wrapper = styled.div`
  padding: ${({ theme }) => 3 * theme.spacer}px;
`

export interface ContentfulText {
  __typename: "ContentfulText"
  id: string
  text: ContentfulRichText
}

interface TextProps {
  text: ContentfulText
}

export const Text = ({ text }: TextProps): JSX.Element => {
  return (
    <Wrapper>
      <RichText body={text.text} />
    </Wrapper>
  )
}
