import { Icon, PrimaryButton, Title3 } from "@einride/ui"
import styled from "@emotion/styled"
import { Link } from "components/ui/Link/Link"
import { cvars } from "lib/theming/breakpoint"
import { ContentfulLink } from "types/contentful"
import { CountdownTimer } from "./CountdownTimer"

type TakeoverProps = {
  cta: ContentfulLink
  addCountdown: boolean
}

export const Takeover = ({ cta, addCountdown }: TakeoverProps): JSX.Element => {
  const ongoingDateText = "Nov 2 - 20:00 CET / 15:00 EDT."
  const ongoingTitleText = "Einride November Release"
  // const ongoingLinkText = "Watch the stream"
  const finishedDateText = "Intelligent movement at scale."
  const finishedTitleText = "Einride November Release"
  // const finishedLinkText = "Watch now"

  const endDate = new Date("11/02/2022 19:00 UTC")
  const currentDate = new Date()

  const remainingDays = Math.floor(endDate.getTime() - currentDate.getTime())

  let eventHasEnded = false

  if (remainingDays < 0) {
    eventHasEnded = true
  }

  const countdownStartTime =
    Math.floor(remainingDays / (1000 * 60 * 60 * 24)) > 2
      ? 365 * 24 * 60 * 60 * 1000
      : 2 * 24 * 60 * 60 * 1000 // countdown from 2 days
  const countdownRemainingTime = remainingDays

  return (
    <Wrapper>
      {addCountdown && (
        <TakeOverContent>
          <TakeOverTextCenter>
            <TakeOverTextContainer>
              <TakeOverTitle color="primary">
                {eventHasEnded ? finishedTitleText : ongoingTitleText}
              </TakeOverTitle>
              <TransparentText>
                {eventHasEnded ? finishedDateText : ongoingDateText}
              </TransparentText>
            </TakeOverTextContainer>

            {!eventHasEnded && (
              <TransparentText>
                <TakeOverCountdown>
                  <div>
                    <CountdownTimer
                      targetDate={countdownRemainingTime}
                      startDate={countdownStartTime}
                      endDate={endDate}
                    />
                  </div>
                </TakeOverCountdown>
              </TransparentText>
            )}
          </TakeOverTextCenter>
        </TakeOverContent>
      )}

      {cta && (
        <Link to={cta.url}>
          <PrimaryButton rightIcon={<Icon name="arrowRight" />} isFullWidth>
            {cta.text}
          </PrimaryButton>
        </Link>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: ${cvars.sectionFrameSpacing};
  left: ${cvars.sectionFrameSpacing};
  right: ${cvars.sectionFrameSpacing};
  bottom: ${cvars.sectionFrameSpacing};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 ${({ theme }) => theme.spacer * 2}px;
  font-feature-settings: "zero";
  font-variant-numeric: slashed-zero;
  -webkit-font-feature-settings: "zero" 1 !important;
  @media ${({ theme }) => theme.mediaQueries.md} {
    padding: 0 ${({ theme }) => theme.spacer * 3}px;
  }
`

const TakeOverContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  line-height: 1;

  @media screen and (min-width: 600px) and (max-width: 700px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`

const TakeOverTextCenter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`

const TakeOverTextContainer = styled.div``

const TransparentText = styled(Title3)`
  margin: 0;
  color: ${({ theme }) => theme.colors.content.secondary};
  @media ${({ theme }) => theme.mediaQueries.belowMd} {
    margin-bottom: 0.4em;
    font-size: 0.75em;
    line-height: calc(4 / 3);
  }
`

const TakeOverTitle = styled(Title3)`
  margin: 0;
  @media ${({ theme }) => theme.mediaQueries.belowMd} {
    font-size: 0.75em;
    line-height: calc(4 / 3);
  }

  @media ${({ theme }) => theme.mediaQueries.onlyMd} {
    margin: 0;
  }
`

const TakeOverCountdown = styled.div`
  display: none;
  @media ${({ theme }) => theme.mediaQueries.md} {
    display: block;
  }
`
