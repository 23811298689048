import { VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled/macro"
import { BackgroundColor, ContentfulContent } from "components/contentful-ui/Content/Content"
import { RichText } from "components/contentful-ui/RichText/RichText"
import { HideAbove, HideBelow } from "components/shared/styles/Layout"
import { Link } from "components/ui/Link/Link"
import { StyledWithIconButton } from "components/ui/StyledWithIconButton/StyledWithIconButton"
import { cvars, mediaQueries } from "lib/theming/breakpoint"
import { Paragraph, Title1 } from "lib/ui/Typography/Typography"

export interface StatementViewProps {
  className?: string
  content: ContentfulContent
}

export const StatementView = ({ content, className }: StatementViewProps): JSX.Element => {
  const { backgroundColor, link, linkStyle, text, title, secondaryTitle, renderAsStatement } =
    content

  const renderLink = (): JSX.Element | undefined => {
    if (!link) {
      return undefined
    }
    if (linkStyle === "primary") {
      return (
        <Link to={link.url} linkType="cta_click">
          <CtaButton>{link.text}</CtaButton>
        </Link>
      )
    }
    return (
      <Link to={link.url}>
        <LinkText>{link.text}</LinkText>
      </Link>
    )
  }

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      onlyTextLayout={renderAsStatement}
      className={className}
    >
      <Content>
        <Up>
          <div>
            {title && <Title as="h2">{title}</Title>}
            {secondaryTitle && <SubTitle as="h3">{secondaryTitle}</SubTitle>}
            <HideAbove breakpoint={767}>
              <VerticalSpacing size="lg" />
              <VerticalSpacing size="lg" />
            </HideAbove>

            <HideBelow breakpoint={767}>
              <VerticalSpacing size="xl" />
              <VerticalSpacing size="xl" />
            </HideBelow>
          </div>

          <div>
            {text && (
              <ContentText>
                <ContentWrap>
                  <RichText body={text} />
                </ContentWrap>
              </ContentText>
            )}
            {text && link && <ResponsiveSpacing size="lg" />}
            {link && <>{renderLink()}</>}
          </div>
        </Up>

        <>
          <VerticalSpacing size="lg" />
          <VerticalSpacing size="lg" />
        </>
      </Content>
    </Wrapper>
  )
}

const Content = styled.div`
  min-height: auto;
  padding: ${cvars.sectionFrameSpacing};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: auto;
`

const Up = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: "flex-start" 
  height: 100%;
`

const Title = styled(Title1)``

const SubTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.content.secondary};
`

const ContentText = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: var(--grid-gutter);

  ${({ theme }) => `
  p {
    font-size: ${theme.fontSizes["2xl"]};
    line-height: calc(6 / 5);
    font-weight: 500;

    @media ${mediaQueries.md} {
      font-size: ${theme.fontSizes["3xl"]};
      font-weight: ${theme.fontWeights.medium};
      line-height: 1;
    }
  }`}
`

const ContentWrap = styled.div`
  grid-row: 1;
  grid-column: 1/12;
`

const LinkText = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.content.positive};
  text-decoration: underline;
  margin: 0;
`

const Wrapper = styled.div<{
  backgroundColor: BackgroundColor | undefined
  onlyTextLayout: boolean | undefined
}>`
  ${({ backgroundColor, theme }) =>
    `${
      backgroundColor === "background secondary" &&
      `background: ${theme.colors.background.secondary}`
    }
  `}
`

const CtaButton = styled(StyledWithIconButton)`
  margin-top: 0.5rem;
`

const ResponsiveSpacing = styled(VerticalSpacing)`
  height: 40px;
  @media ${mediaQueries.belowMd} {
    height: 24px;
  }
`
