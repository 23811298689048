import { Icon, PrimaryButton, Title1, VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import { RichText } from "components/contentful-ui/RichText/RichText"
import { ContentWrapper, Desktop, Mobile } from "components/shared/styles/Layout"
import { Link } from "components/ui/Link/Link"
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import { cvars, mediaQueries } from "lib/theming/breakpoint"

interface Props {
  content: ContentfulCtaBlock
}

export const CtaBlock = ({ content }: Props): JSX.Element => {
  return (
    <CtaWrapper>
      <Row>
        {content.title && (
          <Col>
            <SectionTitle as="h1">{content.title}</SectionTitle>
            <Mobile>
              <VerticalSpacing size="lg" />
            </Mobile>
          </Col>
        )}

        <Col>
          <StyledRichText isCtaBlock>
            {content.body && <RichText body={content.body} />}
          </StyledRichText>

          {content.link && content.link.url && content.link.text && (
            <>
              <ResponsiveSpacing size="lg" />
              <Link to={content.link.url} linkType="cta_click">
                <CTAButton rightIcon={<Icon name="arrowRight" />}>{content.link.text}</CTAButton>
              </Link>
            </>
          )}
        </Col>
      </Row>
      <Mobile>
        <VerticalSpacing size="lg" />
        <VerticalSpacing size="lg" />
      </Mobile>
      <Desktop>
        <VerticalSpacing size="xl" />
        <VerticalSpacing size="xl" />
      </Desktop>
    </CtaWrapper>
  )
}

export interface ContentfulCtaBlock {
  __typename: "ContentfulCtaBlock"
  id: string
  link?: {
    text: string
    url: string
  }
  body?: RenderRichTextData<ContentfulRichTextGatsbyReference>
  title?: string
}

const StyledRichText = styled.div<{ isCtaBlock: boolean }>`
  h1,
  h2,
  h3 {
    font-weight: 500;
    margin-bottom: ${({ isCtaBlock }) => (isCtaBlock ? "1px" : "25px")};
  }
  p {
    margin-bottom: ${({ isCtaBlock }) => isCtaBlock && "25px"};
  }
  @media ${({ theme }) => theme.mediaQueries.belowMd} {
    h1:first-of-type,
    h2:first-of-type,
    h3:first-of-type,
    p:first-of-type {
      margin-top: 0;
    }
    h1 {
      font-size: 1.75rem;
      font-weight: 500;
      line-height: calc(8 / 7);
    }
    h2 {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: calc(4 / 3);
    }
    h3 {
      font-size: 1.125rem;
      font-weight: 450;
      line-height: calc(4 / 3);
      margin-top: 16px;
      margin-bottom: 8px;
    }
  }
  @media ${({ theme }) => theme.mediaQueries.md} {
    h1:first-of-type,
    h2:first-of-type,
    h3:first-of-type {
      margin-top: 0;
    }

    p:first-of-type {
      margin-top: calc(1em / 4);
    }

    h3 {
      margin-bottom: ${({ isCtaBlock }) => (isCtaBlock ? "2px" : "25px")};
    }
    p {
      margin-bottom: ${({ isCtaBlock }) => isCtaBlock && "24px"};
    }
  }
`

const ResponsiveSpacing = styled(VerticalSpacing)`
  height: 40px;
  @media ${mediaQueries.belowMd} {
    height: 24px;
  }
`

const CtaWrapper = styled(ContentWrapper)`
  padding: ${({ theme }) => 2 * theme.spacer}px;
  color: ${({ theme }) => theme.colors.content.primary};
  @media ${({ theme }) => theme.mediaQueries.md} {
    padding: ${({ theme }) => 3 * theme.spacer}px;
  }
`

const SectionTitle = styled(Title1)`
  @media ${({ theme }) => theme.mediaQueries.belowMd} {
    margin-bottom: 0;
    font-size: ${({ theme }) => theme.fontSizes.lg};
    line-height: calc(4 / 3);
  }
`

const Row = styled.div`
  @media ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
    margin: 0 calc((${cvars.gridGutter} / 2) * -1);
  }
`

const Col = styled.div`
  @media ${({ theme }) => theme.mediaQueries.md} {
    width: 100%;
    max-width: 50%;
    padding: 0 calc(${cvars.gridGutter} / 2);
  }
`

const CTAButton = styled(PrimaryButton)`
  --einride-ui-span: 4;
  @media ${({ theme }) => theme.mediaQueries.md} {
    --einride-ui-span: 3;
  }
  min-width: calc(
    (
        (100vw - calc(var(--einride-ui-grid-columns) + 1) * var(--einride-ui-grid-gap)) /
          var(--einride-ui-grid-columns) + var(--einride-ui-grid-gap)
      ) * var(--einride-ui-span) - var(--einride-ui-grid-gap)
  );
`
