import { useCountdown } from "hooks/useCountdown"
import Countdown from "react-countdown"

interface RendererProps {
  days: number
  hours: number
  minutes: number
  completed: boolean
}

const ShowCounter = ({
  days,
  hours,
  minutes,
  displayFull,
}: {
  days: number
  hours: number
  minutes: number
  displayFull: boolean
}): JSX.Element => {
  if (displayFull) {
    return (
      <div>
        {days}d {hours}h {minutes}m
      </div>
    )
  }
  return <div>{`${days} days to go`}</div>
}

export const CountdownTimer = ({
  targetDate,
  startDate,
  endDate,
}: {
  targetDate: number
  startDate: number
  endDate: Date
}): JSX.Element => {
  const [cdays, chours, cminutes] = useCountdown(targetDate, startDate)
  const renderer = ({ days, hours, minutes }: RendererProps): JSX.Element => {
    return (
      <span>
        {days}d {hours}h {minutes}m
      </span>
    )
  }

  if (cdays + chours + cminutes <= 0 && Math.floor(targetDate / (1000 * 60 * 60 * 24)) < 2) {
    return <Countdown date={endDate} renderer={renderer} />
  }
  return (
    <ShowCounter
      days={cdays}
      hours={chours}
      minutes={cminutes}
      displayFull={Math.floor(targetDate / (1000 * 60 * 60 * 24)) < 2}
    />
  )
}
