import { VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import { Link } from "components/ui/Link/Link"
import { GatsbyImage } from "gatsby-plugin-image"
import { cvars, mediaQueries } from "lib/theming/breakpoint"
import { Paragraph, Title1 } from "lib/ui/Typography/Typography"
import { ContentfulImage, ContentfulLink } from "types/contentful"

interface FooterLinkProps {
  footerLink: ContentfulFooterLink
}

export const FooterLink = ({ footerLink }: FooterLinkProps): JSX.Element => {
  const { leftTitle, media, mobileMedia, rightTitle, link } = footerLink

  return (
    <Wrapper>
      <Top>
        <Left>
          <Title1 as="h2">{leftTitle}</Title1>
          <VerticalSpacing />
        </Left>
        <Right>
          <Title1 as="h2">{rightTitle}</Title1>
          <VerticalSpacing size="lg" />
          {link && link.url && (
            <Link to={link.url}>
              <StyledParagraph>{link.text}</StyledParagraph>
            </Link>
          )}
        </Right>
      </Top>
      {media && media.media && (
        <ImageWrapper>
          <DesktopImage image={media.media.gatsbyImageData} alt={media.altText} />
          <MobileImage
            image={
              mobileMedia && mobileMedia.media
                ? mobileMedia.media.gatsbyImageData
                : media.media.gatsbyImageData
            }
            alt={mobileMedia ? mobileMedia.altText : media.altText}
          />
        </ImageWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.background.primary};
  height: ${cvars.sectionHeight};
  display: flex;
  flex-direction: column;

  @media ${mediaQueries.md} {
    height: ${({ theme }) => 115 * theme.spacer}px;
    overflow: hidden;
  }
`

const Top = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacer}px;
  padding: ${cvars.sectionFrameSpacing};
  padding-bottom: ${({ theme }) => 4 * theme.spacer}px;
  @media ${mediaQueries.md} {
    grid-template-columns: 50% 50%;
  }
`

const Left = styled.div``

const Right = styled.div``

const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.positive};
  text-decoration: underline;
`

const ImageWrapper = styled.div`
  height: 100%;
  padding-left: ${cvars.sectionFrameSpacing};
  padding-right: ${cvars.sectionFrameSpacing};
`

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

const DesktopImage = styled(Image)`
  display: none;

  @media ${mediaQueries.md} {
    display: block;
  }
`

const MobileImage = styled(Image)`
  @media ${mediaQueries.md} {
    display: none;
  }
`

export interface ContentfulFooterLink {
  __typename: "ContentfulFooterLink"
  id: string
  leftTitle: string
  media: ContentfulImage
  mobileMedia?: ContentfulImage
  link: ContentfulLink
  rightTitle: string
}
