import { TextInput, VerticalLayout, VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import * as Sentry from "@sentry/gatsby"
import { RichText } from "components/contentful-ui/RichText/RichText"
import { Desktop, Mobile } from "components/shared/styles/Layout"
import { Link } from "components/ui"
import { StyledWithIconButton } from "components/ui/StyledWithIconButton/StyledWithIconButton"
import { ThemeProvider } from "lib/theming/ThemeProvider/ThemeProvider"
import { Paragraph } from "lib/ui/Typography/Typography"
import { ChangeEvent, useEffect, useState } from "react"
import { pushDownloadData } from "utils/tracking"
import { FormGdpr } from "../../fragments/FormGdpr/FormGdpr"
import { ContentfulForm, Left, RichTextContainer, Right, Title, Wrapper } from "./Form"
import { suite } from "./suite"

interface FormProps {
  form: ContentfulForm
}

export const DocumentDownloadForm = ({ form }: FormProps): JSX.Element => {
  const [email, setEmail] = useState<string>("")
  const [formStatus, setFormStatus] = useState<"success" | "error" | "member_exists" | "">("")

  useEffect(() => {
    if (formStatus === "success" || formStatus === "member_exists") {
      if (!form.documentField) {
        pushDownloadData("document_download_failed", undefined)
        return
      }

      pushDownloadData("document_download_success", form.documentField.document.file.url)

      if (formStatus === "success") {
        const link = document.createElement("a")
        link.target = "_blank"
        link.href = form.documentField.document.file.url
        link.setAttribute("download", form.documentField.document.file.fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }, [formStatus, form])

  const handleClick = async (): Promise<void> => {
    const suiteRes = suite({ email })
    if (suiteRes.hasErrors()) {
      setFormStatus("error")
      pushDownloadData("document_download_failed", form.documentField.document.file.url)
      return
    }

    try {
      const response = await window.fetch("/api/document-download", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          email_address: email,
          status: "subscribed",
          mailchimpAudienceId: form.mailchimpAudienceId,
        }),
      })

      const result = await response.json()

      if (result.status === 400 && result.title === "Member Exists") {
        setFormStatus("member_exists")
      } else if (result.status === 200 || !result.errors) {
        setFormStatus("success")
      } else {
        setFormStatus("error")
        pushDownloadData("document_download_failed", form.documentField?.document.file.url)
      }
    } catch (err) {
      setFormStatus("error")
      Sentry.captureException(err)
    }
  }
  return (
    <Wrapper>
      <Left>
        <Title>{form.leftTitle}</Title>
      </Left>
      <Right>
        <Mobile>
          <VerticalSpacing size="lg" />
          <VerticalSpacing size="lg" />
        </Mobile>
        <Title>{form.rightTitle}</Title>
        <Desktop>
          <VerticalSpacing size="xl" />
          <VerticalSpacing size="xl" />
        </Desktop>
        <div>
          {form.body && (
            <RichTextContainer>
              <RichText body={form.body} />
            </RichTextContainer>
          )}
          <VerticalSpacing size="md" />

          {/* <div>Server response: {serverResponse}</div> */}

          {formStatus !== "success" ? (
            <>
              <StyledVerticalLayout>
                <TextInput
                  aria-label="Email"
                  type="email"
                  name="email_address"
                  placeholder="Email"
                  value={email}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                  required
                />
                <ThemeProvider colorMode="light">
                  <FormGdpr noSpacing />
                </ThemeProvider>
              </StyledVerticalLayout>
              <VerticalSpacing size="md" />
              <button type="button" onClick={() => handleClick()} style={{ width: "100%" }}>
                <StyledWithIconButton>{form.formSubmitText}</StyledWithIconButton>
              </button>
              <VerticalSpacing size="sm" />

              {formStatus === "error" && (
                <Paragraph>Unfortunately, the subscription did not succeed.</Paragraph>
              )}

              {formStatus === "member_exists" && (
                <>
                  <Paragraph>Seems like you are already subscribed with this email.</Paragraph>
                  {form.documentField && (
                    <>
                      <Paragraph>To re-download the file, click the download link.</Paragraph>
                      <Paragraph>
                        <Link to={`http:${form.documentField.document.file.url}`}>Download →</Link>
                      </Paragraph>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <Paragraph>
              Submission was successful!
              {form.documentField && <span> Your download is now available.</span>}
            </Paragraph>
          )}
        </div>
      </Right>
    </Wrapper>
  )
}

const StyledVerticalLayout = styled(VerticalLayout)`
  align-items: stretch;
`
