import { Icon, PrimaryButton } from "@einride/ui"
import styled from "@emotion/styled"
import { RichText } from "components/contentful-ui/RichText/RichText"
import { Desktop, Mobile } from "components/shared/styles/Layout"
import { Link } from "components/ui/Link/Link"
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import { cvars } from "lib/theming/breakpoint"
import { Title1, Title2 } from "lib/ui/Typography/Typography"

export interface ContentfulIntroFlexibleHeight {
  __typename: "ContentfulIntroFlexibleHeight"
  id: string
  leftTitlePart1: string
  leftTitlePart2: string

  link?: {
    text: string
    url: string
  }
  rightTitle: string
  text: RenderRichTextData<ContentfulRichTextGatsbyReference>
}

const Wrapper = styled.div`
  display: grid;
  min-height: ${cvars.sectionHeight};
  gap: ${cvars.gridGutter};
  padding: ${cvars.sectionFrameSpacing};

  @media ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
`

const Left = styled.div``

const Title = styled(Title1)``

const VerticalSpacing = styled.div`
  height: ${({ theme }) => 4 * theme.spacer}px;

  @media ${({ theme }) => theme.mediaQueries.md} {
    height: ${({ theme }) => 6 * theme.spacer}px;
  }
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: ${({ theme }) => theme.spacer}px;

  @media ${({ theme }) => theme.mediaQueries.md} {
    margin-top: unset;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => 2 * theme.spacer}px;
  }
`

interface IntroFlexibleHeightProps {
  intro: ContentfulIntroFlexibleHeight
}

export const IntroFlexibleHeight = ({ intro }: IntroFlexibleHeightProps): JSX.Element => {
  return (
    <Wrapper>
      <Left>
        <Title>{intro.leftTitlePart1}</Title>
        <Title color="secondary">{intro.leftTitlePart2}</Title>

        <Mobile>
          <VerticalSpacing />
          <Title2>{intro.rightTitle}</Title2>
        </Mobile>
      </Left>
      <Right>
        <Desktop>
          <Title1 as="h2">{intro.rightTitle}</Title1>
        </Desktop>
        <div>
          <RichText body={intro.text} />
          {intro.link && (
            <>
              <VerticalSpacing />
              <Link to={intro.link.url}>
                <PrimaryButton rightIcon={<Icon name="arrowRight" />}>
                  {intro.link.text}
                </PrimaryButton>
              </Link>
            </>
          )}
        </div>
      </Right>
    </Wrapper>
  )
}
