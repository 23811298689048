/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import { RichText } from "components/contentful-ui/RichText/RichText"

import { HideAbove, HideBelow } from "components/shared/styles/Layout"
import { Link } from "components/ui/Link/Link"
import { StyledWithIconButton } from "components/ui/StyledWithIconButton/StyledWithIconButton"

import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import { cvars, mediaQueries } from "lib/theming/breakpoint"
import { MegaTitle } from "lib/ui/Typography/Typography"
import { ContentfulLink } from "types/contentful"
import { formMapper } from "utils/formMapper"

export interface ContentfulContact {
  __typename: "ContentfulContact"
  id: string
  title: string
  text: RenderRichTextData<ContentfulRichTextGatsbyReference>
  link: ContentfulLink
  form?: string
  leadSource?: string
}

const Wrapper = styled.div`
  display: grid;
  gap: ${cvars.gridGutter};
  padding: ${cvars.sectionFrameSpacing};

  @media ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: 1fr 1fr;
  }
`

const Left = styled.div``

const Right = styled.div`
  display: flex;
  flex-direction: column;
`

const Spacer = styled.div`
  flex: 1;
`

const ResponsiveSpacing = styled(VerticalSpacing)`
  height: 40px;
  @media ${mediaQueries.belowMd} {
    height: 24px;
  }
`

interface ContactProps {
  contact: ContentfulContact
}

export const Contact = ({ contact }: ContactProps): JSX.Element => {
  return (
    <Wrapper>
      <HideBelow breakpoint={599}>
        <Left />
      </HideBelow>
      <Right>
        {contact.title && <a id={contact.title.toLocaleLowerCase().replaceAll(" ", "-")} />}
        <MegaTitle as="h2">{contact.title}</MegaTitle>

        <HideAbove breakpoint={767}>
          <VerticalSpacing size="lg" />
          <VerticalSpacing size="lg" />
          <VerticalSpacing size="lg" />
        </HideAbove>

        <HideBelow breakpoint={767}>
          <VerticalSpacing size="xl" />
          <VerticalSpacing size="xl" />
          <VerticalSpacing size="xl" />
        </HideBelow>

        <Spacer />
        <RichText body={contact.text} />

        {!contact.form ? (
          <>
            <ResponsiveSpacing size="lg" />
            <Link to={contact.link.url} linkType="cta_click">
              <StyledWithIconButton>{contact.link.text}</StyledWithIconButton>
            </Link>
            <VerticalSpacing size="sm" />
          </>
        ) : (
          <>
            <VerticalSpacing size="md" />
            {formMapper(contact.form, contact.leadSource)}
          </>
        )}
      </Right>
    </Wrapper>
  )
}
