import styled from "@emotion/styled"
import { Media } from "components/contentful-ui/Media/Media"
import { Desktop, Mobile } from "components/shared/styles/Layout"
import { cvars } from "lib/theming/breakpoint"
import { ContentfulLink, ContentfulMedia } from "types/contentful"
import { Takeover } from "./Takeover"

interface HeroProps {
  hero: ContentfulHero
}

export const Hero = ({ hero }: HeroProps): JSX.Element => {
  return (
    <Wrapper isImage={hero.media.__typename === "ContentfulImage"}>
      <BackgroundFallback />
      {hero.media && (
        <>
          {hero.media.__typename === "ContentfulImage" ? (
            <>
              <DesktopBgImage>
                <BackgroundMedia media={hero.media} />
              </DesktopBgImage>
              <MobileBgImage>
                <BackgroundMedia media={hero.mobileMedia ?? hero.media} />
              </MobileBgImage>
            </>
          ) : (
            <>
              <DesktopBgVideo>
                <BackgroundMedia media={hero.media} />
              </DesktopBgVideo>
              <MobileBgVideo>
                <BackgroundMedia media={hero.mobileMedia ?? hero.media} />
              </MobileBgVideo>
            </>
          )}
          {(hero.addCountdownTakeover || hero.cta) && (
            <Takeover addCountdown={hero.addCountdownTakeover ?? false} cta={hero.cta} />
          )}
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isImage: boolean }>`
  position: relative;
  padding: 0 ${cvars.sectionFrameSpacing};
  background: white;

  ${({ isImage, theme }) =>
    isImage &&
    `
    height: calc(78vh - ${cvars.menuHeight});

    @media ${theme.mediaQueries.md} {
      height: calc(94vh - ${cvars.menuHeight});
    }
    `}
`

const BackgroundFallback = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.background.primary};
`

const DesktopBgVideo = styled(Desktop)`
  position: relative;
  width: 100%;
`

const DesktopBgImage = styled(Desktop)`
  position: relative;
  height: 100%;
  grid-area: 1/1;
`

const BackgroundMedia = styled(Media)`
  object-fit: cover;
`

const MobileBgImage = styled(Mobile)`
  position: relative;
  height: 100%;
  grid-area: 1/1;
`

const MobileBgVideo = styled(Mobile)`
  position: relative;
  width: 100%;
`

export interface ContentfulHero {
  __typename: "ContentfulHero"
  id: string
  cta: ContentfulLink
  media: ContentfulMedia
  mobileMedia?: ContentfulMedia
  addCountdownTakeover?: boolean
}
