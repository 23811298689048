import { HorizontalSpacing, IconButton } from "@einride/ui"
import styled from "@emotion/styled/macro"
import { mediaQueries } from "lib/theming/breakpoint"

interface ControlsProps {
  onPrevious: () => void
  onNext: () => void
  hideOnDesktop?: boolean
  hideOnMobile?: boolean
}

const Wrapper = styled.div<{ hideOnDesktop: boolean; hideOnMobile: boolean }>`
  display: flex;
  padding-top: 1.1875rem;
  ${({ hideOnDesktop }) => (hideOnDesktop ? `@media ${mediaQueries.lg} {display: none;}` : "")}
  ${({ hideOnMobile }) => (hideOnMobile ? `@media ${mediaQueries.belowMd} {display: none;}` : "")}
`

export const Controls = ({
  onPrevious,
  onNext,
  hideOnDesktop = false,
  hideOnMobile = false,
}: ControlsProps): JSX.Element => {
  return (
    <Wrapper hideOnDesktop={hideOnDesktop} hideOnMobile={hideOnMobile}>
      <IconButton
        variant="secondary"
        aria-label="Previous card"
        icon="arrowLeft"
        onClick={() => onPrevious()}
      />
      <HorizontalSpacing size="sm" />
      <IconButton
        variant="secondary"
        aria-label="Next card"
        icon="arrowRight"
        onClick={() => onNext()}
      />
    </Wrapper>
  )
}
