import styled from "@emotion/styled"
import { ContentWrapper } from "components/shared/styles/Layout"
import { mediaQueries } from "lib/theming/breakpoint"
import { useEffect, useMemo, useState } from "react"
import { ThemeMode } from "types/common"
import { ContentfulImageCard, ImageCard } from "../ImageCard/ImageCard"
import { Controls } from "./Controls"

const RowWrapper = styled(ContentWrapper)`
  display: flex;
  justify-content: center;
  padding: var(--section-frame-spacing) 0 0 0;
  &:first-of-type {
    padding: 0;
    overflow-x: hidden;
  }
  flex-direction: column;
  @media ${mediaQueries.md} {
    min-height: 538px;
  }
`

const ItemsWrapper = styled.div<{
  numberOfItems: number
  renderAsCaroussel: boolean
  carouselOnMobile: boolean
  shouldItemsScaletoFitParentSpace: boolean
}>`
  //width: calc(100% + ${({ theme }) => 3 * theme.spacer}px);
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  column-gap: ${({ theme }) => 3 * theme.spacer}px;
  //margin-right: -${({ theme }) => 3 * theme.spacer}px;
  row-gap: ${({ theme }) => 3 * theme.spacer}px;
  color: ${({ theme }) => theme.colors.content.primary};

  ${({ shouldItemsScaletoFitParentSpace, renderAsCaroussel, theme }) =>
    shouldItemsScaletoFitParentSpace && !renderAsCaroussel
      ? `> * {
        flex: 1;
      }`
      : `> * {
        flex: 0 0 calc(25% - ${3 * theme.spacer}px);
      }`}

  ${({
    renderAsCaroussel,
    numberOfItems,
    theme,
    carouselOnMobile,
    shouldItemsScaletoFitParentSpace,
  }) =>
    renderAsCaroussel
      ? `

        @media ${mediaQueries.belowMd} {
          ${
            carouselOnMobile
              ? `
                flex-wrap: nowrap;
                overflow: hidden;
                `
              : "flex-wrap: wrap;"
          }
        }

        @media ${mediaQueries.belowLg} {
          > * {
            flex-basis: calc(33% - ${3 * theme.spacer}px);
          }
        }
        @media ${mediaQueries.belowMd} {
          > * {
            flex-basis: calc(50% - ${3 * theme.spacer}px);
          }
        }
        @media ${mediaQueries.belowSm} {
          > * {
            flex-basis: 100%;
          }
        }`
      : `
      flex-wrap: wrap;
      @media ${mediaQueries.belowLg} {
        ${
          carouselOnMobile
            ? `flex-wrap: nowrap;
        overflow: hidden;`
            : "flex-wrap: wrap;"
        }
      }
        
        > * {
          flex: ${shouldItemsScaletoFitParentSpace ? "1" : `0 0 calc(25% - ${3 * theme.spacer}px)`};
          

          > .title,  > .description p, > .description a { 
            @media ${mediaQueries.belowMd} {
              ${
                numberOfItems === 4
                  ? ` font-size: 14px;
              line-height: 16px;`
                  : ""
              };
            }
          } 
          
          @media ${mediaQueries.belowMd} {
            flex-basis: calc(${numberOfItems === 4 ? "50%" : "33%"} - ${3 * theme.spacer}px);
          }
          @media ${mediaQueries.belowSm} {
            flex-basis: calc(${numberOfItems === 4 ? "50%" : "100%"} - ${3 * theme.spacer}px);
          }
        }`}
`

export interface ContentfulCardRow {
  __typename: "ContentfulCardRow"
  id: string
  items: ContentfulImageCard[]
  enableCaroussel: boolean
  disableCarousselOnMobile: boolean
  shouldItemsScaletoFitParentSpace: boolean
}

interface RowProps {
  component: ContentfulCardRow
  themeMode?: ThemeMode
}
const MAX_ITEMS = 4
export const CardRow = ({ component, themeMode = "light" }: RowProps): JSX.Element => {
  const [cards, setCards] = useState<React.ReactElement[]>([])

  useEffect(() => {
    const len = component.items.length
    const tmpCards: React.ReactElement[] = []
    for (let i = 0; i < len; i += 1) {
      const card: ContentfulImageCard = component.items[i]
      tmpCards.push(
        <ImageCard
          description={card.description}
          title={card.title}
          media={card.media}
          key={card.id}
          themeMode={themeMode}
        />,
      )
      setCards(tmpCards)
    }
  }, [component, themeMode])

  const numberOfItems = useMemo(() => {
    if (component.enableCaroussel) {
      return component.items.length > MAX_ITEMS ? MAX_ITEMS : component.items.length
    }

    return component.items.length
  }, [component])

  const rebuildCardList = (direction: string): void => {
    const tmp = [...cards]

    if (tmp.length > 0) {
      if (direction === "next") {
        const firstEl: React.ReactElement | undefined = tmp.shift()
        if (firstEl) tmp.push(firstEl)
      } else {
        const lastEl: React.ReactElement | undefined = tmp.pop()
        if (lastEl) tmp.unshift(lastEl)
      }
    }
    setCards(tmp)
  }

  const handleNext = (): void => {
    rebuildCardList("next")
  }
  const handlePrevious = (): void => {
    rebuildCardList("previous")
  }

  return (
    <RowWrapper>
      <ItemsWrapper
        numberOfItems={numberOfItems}
        renderAsCaroussel={component.enableCaroussel}
        carouselOnMobile={!component.disableCarousselOnMobile}
        shouldItemsScaletoFitParentSpace={component.shouldItemsScaletoFitParentSpace}
      >
        {!component.disableCarousselOnMobile && component.enableCaroussel
          ? cards.slice(0, MAX_ITEMS)
          : cards}
      </ItemsWrapper>

      {(component.items.length > MAX_ITEMS || !component.disableCarousselOnMobile) && (
        <Controls
          hideOnDesktop={!component.enableCaroussel || component.items.length <= MAX_ITEMS}
          hideOnMobile={component.disableCarousselOnMobile}
          onPrevious={() => handlePrevious()}
          onNext={() => handleNext()}
        />
      )}
    </RowWrapper>
  )
}
