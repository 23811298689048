import { useEffect, useRef } from "react"

export const useVideoPlayback = (): React.RefObject<HTMLVideoElement>[] => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const { current } = videoRef

  const isPlaying =
    current?.currentTime !== 0 &&
    !current?.paused &&
    !current?.ended &&
    current?.readyState &&
    current.readyState > current?.HAVE_CURRENT_DATA

  useEffect(() => {
    const callback: IntersectionObserverCallback = async (entries) => {
      const [entry] = entries
      if (entry.isIntersecting && !isPlaying) {
        await current?.play()
        return
      }

      if (!entry.isIntersecting && isPlaying) {
        current?.pause()
      }
    }
    const observer = new IntersectionObserver(callback)
    if (current) observer.observe(current)

    current?.load()

    return () => {
      if (current) observer.unobserve(current)
    }
  }, [current, isPlaying, videoRef])

  return [videoRef]
}
