import styled from "@emotion/styled"
import { RichText } from "components/contentful-ui/RichText/RichText"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import { mediaQueries } from "lib/theming/breakpoint"
import { ThemeMode } from "types/common"
import { ContentfulImage } from "types/contentful"

const Wrapper = styled.div<{ themeMode: ThemeMode }>`
  display: flex;
  flex-direction: column;
  font-feature-settings: "zero";
  font-variant-numeric: slashed-zero;
  -webkit-font-feature-settings: "zero" 1 !important;
  width: 100%;
  a {
    color: ${({ theme }) => theme.colors.content.positive};
  }
  p {
    margin-top: 0;
    padding-top: 0;
  }
  box-shadow: ${({ themeMode }) =>
    themeMode === "dark" ? " inset 0px 1px 0px #7A7A7A" : "inset 0px 1px 0px #dadada"};
`

const GImage = styled(GatsbyImage)`
  width: 100%;
  color: transparent;
`

const Image = styled.img`
  width: 100%;
  color: transparent;
`

const Title = styled.span<{ themeMode: ThemeMode }>`
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: ${({ theme }) => theme.spacer}px;
  color: ${({ themeMode }) => (themeMode === "light" ? "#1e1e1f" : "#FFF")};
  margin-top: 0.8125em;
`

const Description = styled.div<{ themeMode: ThemeMode }>`
  color: ${({ themeMode }) =>
    themeMode === "light" ? "rgba(30, 30, 31, 0.6)" : "rgba(245, 245, 245, 0.7)"};
  @media ${mediaQueries.lg} {
    min-height: 160px;
  }
`

export interface ContentfulImageCard {
  __typename: "ContentfulCard"
  id: string
  media: ContentfulImage | string
  title: string
  description: RenderRichTextData<ContentfulRichTextGatsbyReference>
}

interface ImageCardProps {
  media: ContentfulImage | string
  title: string
  description: RenderRichTextData<ContentfulRichTextGatsbyReference> | string
  themeMode?: ThemeMode
}

export const ImageCard = ({
  media,
  title,
  description,
  themeMode,
}: ImageCardProps): JSX.Element => {
  return (
    <Wrapper themeMode={themeMode}>
      {typeof media !== "string" && media?.media && (
        <GImage
          image={media.media.gatsbyImageData}
          imgStyle={{ objectFit: "cover" }}
          alt={media.altText}
        />
      )}
      {typeof media === "string" && <Image src={media} />}
      <Title className="title" themeMode={themeMode}>
        {title}
      </Title>
      <Description className="description" themeMode={themeMode}>
        {typeof description === "string" ? description : <RichText body={description} />}
      </Description>
    </Wrapper>
  )
}
