import styled from "@emotion/styled/macro"
import { DesktopView } from "components/contentful-ui/Content/components/DesktopView/DesktopView"
import { MobileView } from "components/contentful-ui/Content/components/MobileView/MobileView"
import { ObjectFit } from "components/contentful-ui/Media/Media"
import { mediaQueries } from "lib/theming/breakpoint"
import { ContentfulLink, ContentfulMedia, ContentfulRichText } from "types/contentful"
import { StatementView } from "./components/StatementView/StatementView"

export interface ContentProps {
  content: ContentfulContent
}

const Wrapper = styled.div<{
  backgroundColor: BackgroundColor | undefined
  linkStyle: "primary" | "secondary" | undefined
}>`
  ${({ backgroundColor, theme }) =>
    backgroundColor === "background secondary" &&
    `background: ${theme.colors.background.secondary}`};
  display: grid;
  position: relative;

  a {
    color: ${({ theme, linkStyle }) =>
      linkStyle ? theme.colors.content[linkStyle] : theme.colors.content.positive};
  }
`

const Desktop = styled(DesktopView)`
  display: none;

  @media ${mediaQueries.md} {
    display: grid;
  }
`

const Mobile = styled(MobileView)`
  @media ${mediaQueries.md} {
    display: none;
  }
`

export const Content = ({ content }: ContentProps): JSX.Element => {
  return (
    <Wrapper backgroundColor={content.backgroundColor} linkStyle={content.linkStyle}>
      {content.renderAsStatement ? (
        <StatementView content={content} />
      ) : (
        <>
          <Mobile content={content} />
          <Desktop content={content} />
        </>
      )}
    </Wrapper>
  )
}

export type BackgroundColor = "background secondary"
export type Layout = "text left" | "text right"
export type TitleAlignment = "Left aligned" | "Right aligned"

export interface ContentfulContent {
  __typename: "ContentfulContent"
  backgroundColor?: BackgroundColor
  backgroundMedia?: ContentfulMedia
  foregroundMedia?: ContentfulMedia
  foregroundMediaFit?: ObjectFit
  mobileBackgroundMedia?: ContentfulMedia
  mobileForegroundMedia?: ContentfulMedia
  id: string
  layout: Layout
  titleAlignment: TitleAlignment
  link?: ContentfulLink
  linkStyle?: "primary" | "secondary" | undefined
  text?: ContentfulRichText
  title?: string
  secondaryTitle?: string
  renderAsStatement?: boolean
}
