import { WindowLocation } from "@reach/router"
import { Seo } from "components/fragments/Seo/Seo"
import { PageView } from "components/views/PageView/PageView"
import { graphql } from "gatsby"
import { ThemeMode } from "types/common"
import { ContentfulImage, PageComponents } from "types/contentful"

interface PageProps {
  data: {
    page: {
      metaDescription: string
      metaImage?: ContentfulImage
      metaTitle: string
      metaNoIndexNoFollow?: boolean
      sections?: {
        components?: PageComponents[]
        id: string
        theme?: ThemeMode
      }[]
    }
  }
  location: WindowLocation
}

const Page = ({ data, location }: PageProps): JSX.Element => {
  const { page } = data

  return (
    <>
      <Seo
        description={page.metaDescription}
        image={page.metaImage?.media.gatsbyImageData}
        pathname={location.pathname}
        title={page.metaTitle}
        noIndexNoFollow={page.metaNoIndexNoFollow ?? false}
      />
      <PageView page={page} addCookie={location.pathname.includes("/privacy-policy")} />
    </>
  )
}

export const query = graphql`
  query PageQuery($id: String) {
    page: contentfulPage(id: { eq: $id }) {
      ...ContentfulPage
    }
  }

  fragment ContentfulCard on ContentfulCard {
    id
    __typename
    media {
      ... on ContentfulImage {
        ...ContentfulImage
      }
    }
    title
    description {
      raw
      references {
        ... on ContentfulPage {
          __typename
          slug
          name
          contentful_id
        }
        ... on ContentfulLink {
          ...ContentfulLink
        }
      }
    }
  }

  fragment ContentfulCardRow on ContentfulCardRow {
    __typename
    id
    disableCarousselOnMobile
    shouldItemsScaletoFitParentSpace
    enableCaroussel
    items {
      ... on ContentfulCard {
        ...ContentfulCard
      }
    }
  }

  fragment ContentfulKPIFields on ContentfulKpi {
    __typename
    contentful_id
    sys {
      type
    }
    id
    bigText
    intro
    title
    content {
      raw
      references {
        ... on ContentfulPage {
          __typename
          slug
          name
          contentful_id
        }
        ... on ContentfulCtaBlock {
          __typename
          id
          contentful_id
          name
          title
          body {
            raw
          }
          link {
            ...ContentfulLink
          }
        }
      }
    }
  }

  fragment ContentfulKpiRecursive on ContentfulKpi {
    ...ContentfulKPIFields
    content {
      raw
      references {
        ... on ContentfulKpi {
          ...ContentfulKPIFields
        }
        ... on ContentfulPage {
          __typename
          slug
          name
          contentful_id
        }
        ... on ContentfulCtaBlock {
          __typename
          id
          contentful_id
          name
          title
          body {
            raw
          }
          link {
            ...ContentfulLink
          }
        }
      }
    }
  }

  fragment ContentfulKpiRow on ContentfulKpiRow {
    __typename
    id
    items {
      ...ContentfulKpiRecursive
    }
  }

  fragment ContentfulRowsBlock on ContentfulRowsBlock {
    __typename
    id
    blockTitle
    rows {
      ... on ContentfulKpiRow {
        ...ContentfulKpiRow
      }
      ... on ContentfulCardRow {
        ...ContentfulCardRow
      }
    }
  }
  fragment ContentfulLink on ContentfulLink {
    __typename
    url
    text
    contentful_id
  }
  fragment ContentfulContent on ContentfulContent {
    __typename
    backgroundColor
    backgroundMedia: backgroundMedia2 {
      ... on ContentfulImage {
        __typename
        altText
        media {
          gatsbyImageData(quality: 100)
        }
      }
      ... on ContentfulVideo {
        ...ContentfulVideo
      }
    }
    foregroundMedia {
      ... on ContentfulImage {
        ...ContentfulImage
      }
      ... on ContentfulVideo {
        ...ContentfulVideo
      }
    }
    foregroundMediaFit
    mobileBackgroundMedia: mobileBackgroundMedia2 {
      ... on ContentfulImage {
        __typename
        altText
        media {
          gatsbyImageData(quality: 100)
        }
      }
      ... on ContentfulVideo {
        ...ContentfulVideo
      }
    }
    mobileForegroundMedia {
      ... on ContentfulImage {
        ...ContentfulImage
      }
      ... on ContentfulVideo {
        ...ContentfulVideo
      }
    }
    id
    layout
    titleAlignment
    link {
      ... on ContentfulLink {
        ...ContentfulLink
      }
    }
    linkStyle
    text {
      raw
      references {
        ... on ContentfulAsset {
          __typename
          contentful_id
          id
          file {
            url
          }
        }
      }
    }
    title
    secondaryTitle
    renderAsStatement
  }

  fragment ContentfulFooterLink on ContentfulFooterLink {
    __typename
    id
    leftTitle
    media {
      media {
        gatsbyImageData(quality: 100)
      }
      altText
    }
    mobileMedia {
      media {
        gatsbyImageData(quality: 100)
      }
      altText
    }
    link {
      ...ContentfulLink
    }
    rightTitle
  }

  fragment ContentfulContact on ContentfulContact {
    __typename
    id
    title
    text {
      raw
    }
    link {
      ...ContentfulLink
    }
    form
    leadSource
  }

  fragment ContentfulHero on ContentfulHero {
    __typename
    id
    media {
      ... on ContentfulImage {
        __typename
        id
        altText
        media {
          gatsbyImageData(quality: 100)
        }
      }
      ... on ContentfulVideo {
        ...ContentfulVideo
      }
      ... on Node {
        ... on ContentfulIframe {
          ...ContentfulIframe
        }
      }
    }
    mobileMedia {
      ... on ContentfulImage {
        ...ContentfulImage
      }
      ... on ContentfulVideo {
        __typename
        id
        altText
        isAutoPlaying
        isLooping
        isMuted
        media {
          file {
            url
          }
        }
        showControls
        videoPreviewImage {
          file {
            url
          }
        }
      }
    }
    addCountdownTakeover
    cta {
      ...ContentfulLink
    }
  }

  fragment ContentfulDocument on ContentfulDocument {
    __typename
    id
    document {
      file {
        url
        fileName
      }
    }
  }
  fragment ContentfulIntro on ContentfulIntro {
    __typename
    id
    leftTitlePart1
    leftTitlePart2
    link {
      ... on Node {
        ... on ContentfulDocument {
          ...ContentfulDocument
        }
        ... on ContentfulLink {
          ...ContentfulLink
        }
      }
    }
    name
    rightTitle
    text {
      raw
      references {
        ... on ContentfulAsset {
          __typename
          contentful_id
          id
          file {
            url
          }
        }
      }
    }
    form
    leadSource
    setTextAsTitle
  }

  fragment ContentfulIntroFlexibleHeight on ContentfulIntroFlexibleHeight {
    __typename
    id
    leftTitlePart1
    leftTitlePart2
    link {
      ...ContentfulLink
    }
    name
    rightTitle
    text {
      raw
      references {
        ... on ContentfulAsset {
          __typename
          contentful_id
          id
          file {
            url
          }
        }
      }
    }
  }

  fragment ContentfulSegments on ContentfulSegments {
    __typename
    id
    title
    segments {
      primaryText {
        raw
      }
      secondaryText {
        raw
      }
      title
      media {
        ... on ContentfulImage {
          __typename
          altText
          media {
            gatsbyImageData(aspectRatio: 1)
          }
        }
        ... on ContentfulVideo {
          ...ContentfulVideo
        }
      }
    }
  }
  fragment ContentfulCarousel on ContentfulCarousel {
    __typename
    id
    autoplay
    autoplayInterval
    split
    buttonColor
    title
    buttonColor
    carouselItems {
      id
      title
      desktopMedia: media {
        id
        altText
        media {
          file {
            contentType
            details {
              image {
                width
                height
              }
            }
            url
          }
          gatsbyImageData(width: 2500, placeholder: BLURRED)
          title
        }
      }
      fallbackMedia: media {
        ...CarouselMobileImage
      }
      mobileMedia {
        ...CarouselMobileImage
      }
      description {
        raw
        references {
          ... on ContentfulPage {
            __typename
            slug
            name
            contentful_id
          }
          ... on ContentfulLink {
            ...ContentfulLink
          }
          ... on ContentfulNews {
            __typename
            title
            slug
            contentful_id
          }
          ... on ContentfulPressRelease {
            __typename
            slug
            title
            contentful_id
          }
        }
      }
    }
  }

  fragment ContentfulText on ContentfulText {
    __typename
    id
    text {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          __typename
          file {
            contentType
            url
          }
          gatsbyImageData
          title
        }
        ... on ContentfulPage {
          __typename
          slug
          contentful_id
        }
      }
    }
  }

  fragment ContentfulTaxonomy on ContentfulTaxonomy {
    __typename
    id
    renderAs
    content {
      title
      id
      items {
        id
        icon
        highlighted
        label
        value {
          raw
        }
      }
    }
  }

  fragment ContentfulCtaBlock on ContentfulCtaBlock {
    __typename
    id
    contentful_id
    name
    title
    body {
      raw
    }
    link {
      ...ContentfulLink
    }
  }

  fragment ContentfulForm on ContentfulForm {
    __typename
    id
    name
    leftTitle
    rightTitle
    body {
      raw
    }
    formSubmitText
    mailchimpAudienceId
    formId
    formOverride
    documentField {
      ...ContentfulDocument
    }
  }

  fragment ContentfulImage on ContentfulImage {
    __typename
    altText
    linkToUri
    media {
      gatsbyImageData
    }
  }

  fragment CarouselMobileImage on ContentfulImage {
    id
    altText
    media {
      file {
        contentType
        details {
          image {
            width
            height
          }
        }
        url
      }
      gatsbyImageData(height: 600, placeholder: BLURRED, quality: 80)
      title
    }
  }

  fragment ContentfulVideo on ContentfulVideo {
    __typename
    id
    altText
    isAutoPlaying
    isLooping
    isMuted
    media {
      file {
        url
      }
    }
    mobileMedia {
      file {
        url
      }
    }
    showControls
    videoPreviewImage {
      file {
        url
      }
      gatsbyImageData
      description
    }
    mobileVideoPreviewImage {
      file {
        url
      }
      gatsbyImageData
      description
    }
  }

  fragment ContentfulFaq on ContentfulFaq {
    __typename
    id
    title
    items {
      id
      question
      answer {
        raw
      }
    }
  }

  fragment ContentfulFaqItem on ContentfulFaqItem {
    __typename
    id
    question
    answer {
      raw
    }
  }

  fragment ContentfulTwoColumnContent on ContentfulTwoColumnContent {
    __typename
    name
    id
    leftColumn {
      ... on Node {
        ... on ContentfulText {
          ...ContentfulText
        }
        ... on ContentfulFaq {
          ...ContentfulFaq
        }
        ... on ContentfulVideo {
          ...ContentfulVideo
        }
        ... on ContentfulImage {
          ...ContentfulImage
        }
        ... on ContentfulIframe {
          ...ContentfulIframe
        }
      }
    }
    rightColumn {
      ... on Node {
        ... on ContentfulText {
          ...ContentfulText
        }
        ... on ContentfulFaq {
          ...ContentfulFaq
        }
        ... on ContentfulVideo {
          ...ContentfulVideo
        }
        ... on ContentfulImage {
          ...ContentfulImage
        }
        ... on ContentfulIframe {
          ...ContentfulIframe
        }
      }
    }
  }

  fragment ContentfulCategory on ContentfulCategory {
    name
    metaTitle
    metaDescription
    slug
    order
    labelDiscoverMore
    hideDiscoverMore
    metadata {
      tags {
        contentful_id
      }
    }
  }

  fragment ContentfulTopicList on ContentfulTopicList {
    __typename
    id
    contentful_id
    titleLeft
    titleRight {
      raw
    }
    items {
      ... on ContentfulCard {
        ...ContentfulCard
      }
      ... on ContentfulNews {
        ...ContentfulNews
      }
    }
    display
    numberOfItems
    filterByCategory {
      ...ContentfulCategory
    }
    filterMethod
    readMoreLabel
  }

  fragment ContentfulPage on ContentfulPage {
    metaDescription
    metaImage {
      ...ContentfulImage
    }
    metaTitle
    metaNoIndexNoFollow
    sections {
      components {
        ... on Node {
          ... on ContentfulIframe {
            ...ContentfulIframe
          }
        }
        ... on ContentfulLogo {
          ...ContentfulLogo
        }
        ... on ContentfulTopicList {
          ...ContentfulTopicList
        }
        ... on ContentfulRowsBlock {
          ...ContentfulRowsBlock
        }
        ... on ContentfulContent {
          ...ContentfulContent
        }
        ... on ContentfulTwoColumnContent {
          ...ContentfulTwoColumnContent
        }
        ... on ContentfulFooterLink {
          ...ContentfulFooterLink
        }
        ... on ContentfulContact {
          ...ContentfulContact
        }
        ... on ContentfulHero {
          ...ContentfulHero
        }
        ... on ContentfulIntro {
          ...ContentfulIntro
        }
        ... on ContentfulIntroFlexibleHeight {
          ...ContentfulIntroFlexibleHeight
        }
        ... on ContentfulFaq {
          ...ContentfulFaq
        }
        ... on ContentfulSegments {
          ...ContentfulSegments
        }
        ... on ContentfulCarousel {
          ...ContentfulCarousel
        }
        ... on ContentfulText {
          ...ContentfulText
        }
        ... on ContentfulTaxonomy {
          ...ContentfulTaxonomy
        }
        ... on ContentfulVideo {
          ...ContentfulVideo
        }
        ... on ContentfulCtaBlock {
          ...ContentfulCtaBlock
        }
        ... on ContentfulForm {
          ...ContentfulForm
        }
      }
      id
      theme
    }
  }

  fragment ContentfulNews on ContentfulNews {
    __typename
    metaTitle
    metaDescription
    metaNoIndexNoFollow
    hideImageInFeed
    id
    publishDate(formatString: "M.D.YYYY")
    slug
    title
    highlightsTitle
    featuredImage {
      gatsbyImageData(width: 1300, layout: FULL_WIDTH)
      description
    }
    coverImage {
      ... on ContentfulImage {
        __typename
        altText
        media {
          gatsbyImageData(width: 1300, layout: FULL_WIDTH)
          file {
            url
          }
          description
        }
      }
    }
    feedImage {
      ... on ContentfulImage {
        __typename
        altText
        media {
          gatsbyImageData(width: 1300, layout: FULL_WIDTH)
          file {
            url
          }
          description
        }
      }
    }
    categories {
      ...ContentfulCategory
    }
    excerpt {
      excerpt
    }
    body {
      raw
    }
  }

  fragment ContentfulIframe on ContentfulIframe {
    id
    __typename
    url
    width
    height
  }

  fragment ContentfulLogo on ContentfulLogo {
    __typename
    id
    name
    title
    subtitle
    layout
    text {
      raw
    }
    logos {
      id
      __typename
      media {
        gatsbyImageData
        file {
          contentType
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      altText
    }
  }
`

export default Page
