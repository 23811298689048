/* eslint-disable no-nested-ternary */
import { Paragraph } from "@einride/ui"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { breakpoint } from "lib/theming/breakpoint"
import { Title2 } from "lib/ui/Typography/Typography"
import { isMesh } from "utils/channel"

interface Category {
  name: string
  metadata: {
    tags: [
      {
        contentful_id: string
      },
    ]
  }
}

interface NewsCardProps {
  newsCardItem: {
    id: string
    title?: string
    highlightsTitle?: string
    image?: IGatsbyImageData
    date?: string
    categories: Category[]
    hideImageInFeed?: boolean | undefined
    slug?: string
  }
  isPortrait: boolean
}

export const NewsCard = ({ newsCardItem, isPortrait }: NewsCardProps): JSX.Element => {
  const { title, image, date, categories, hideImageInFeed, highlightsTitle, slug } = newsCardItem
  return (
    <Holder>
      <Link to={`/${process.env.GATSBY_SITE_INSIGHTS_URL}/${slug}`}>
        {image && !hideImageInFeed && (
          <GatsbyImage
            image={image}
            alt={title || ""}
            style={{
              aspectRatio: !isMesh
                ? isPortrait
                  ? "calc(330/396)"
                  : "calc(330/248)"
                : "calc(330/248)",
            }}
          />
        )}
        <TextWrapper>
          <TitleWrapper>
            {categories && (
              <NewsDate>
                {categories.map((item: Category) => {
                  if (
                    item?.metadata?.tags.find(
                      (tag) => tag.contentful_id === process.env.GATSBY_SITE_CHANNEL,
                    )
                  ) {
                    return item.name
                  }
                  return ""
                })}
              </NewsDate>
            )}

            {date && <NewsDate>{date}</NewsDate>}
          </TitleWrapper>
          <NewsTitle>{highlightsTitle || title}</NewsTitle>
        </TextWrapper>
      </Link>
    </Holder>
  )
}

const Holder = styled.div`
  box-shadow: inset 0px 1px 0px #dadada;
  padding: ${({ theme }) => 2 * theme.spacer}px 0px;
  padding-bottom: 30px;
  ${breakpoint.tablet} {
    margin-bottom: 0;
  }
`
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const NewsTitle = styled(Title2)`
  margin-top: 0;
  margin-bottom: 6px;
`

const NewsDate = styled(Paragraph)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin-top: 13px;
  margin-bottom: 10px;
`

const TextWrapper = styled.div``
