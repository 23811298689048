import React, { useEffect, useState } from "react"
import { pushVideoData } from "utils/tracking"

interface VideoPlayerType {
  isPlaying: boolean
  progress: number
  speed: number
  isMuted: boolean
  hasStarted: boolean
  isCompleted: boolean
  togglePlay: () => void
  handleOnCompleted: () => void
  handleVideoProgress: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const useVideoPlayer = (
  videoElement: React.RefObject<HTMLVideoElement>,
  isMuted = false,
  speed = 1,
): VideoPlayerType => {
  const [progress, setProgress] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [hasStarted, setHasStarted] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)

  const togglePlay = (): void => {
    setIsPlaying(!isPlaying)
    setHasStarted(true)
    setIsCompleted(false)
  }

  useEffect(() => {
    if (videoElement.current) {
      // eslint-disable-next-line no-unused-expressions
      if (isPlaying) {
        videoElement.current.play()
        pushVideoData("playback_started")
      } else {
        videoElement.current.pause()
        if (!isCompleted) {
          pushVideoData("playback_paused")
        }
      }
    }
  }, [isPlaying, videoElement, isCompleted])

  const handleVideoProgress = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const manualChange = Number(event.target.value)
    if (videoElement.current) {
      // eslint-disable-next-line no-param-reassign
      videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange
    }

    setProgress(manualChange)
  }

  const handleOnCompleted = (): void => {
    setIsCompleted(true)
    setIsPlaying(false)
    setProgress(0)
    pushVideoData("playback_stopped")
  }

  return {
    togglePlay,
    handleVideoProgress,
    handleOnCompleted,
    progress,
    isPlaying,
    speed,
    isMuted,
    hasStarted,
    isCompleted,
  }
}
