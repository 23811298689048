// Timer.tsx

import styled from "@emotion/styled"
import { useInterval } from "hooks/useInterval"
import { useState } from "react"

interface Props {
  active: boolean
  duration: number
  isHidden: boolean
  startTime: number
}

export const Timer = ({ active, duration, isHidden, startTime }: Props): JSX.Element => {
  const [progress, setProgress] = useState(startTime)

  const convertSecondsToMMSS = (totalSeconds: number): string => {
    const minutes = Math.floor(totalSeconds / 60)

    const seconds = totalSeconds % 60

    const padTo2Digits = (num: number): string => {
      return num.toString().padStart(2, "0")
    }

    return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
  }

  useInterval(() => {
    if (active) {
      if (progress >= duration) {
        setProgress(0)
      } else {
        setProgress(progress + 1)
      }
    }
  }, 1000)

  return (
    <MediaTimer isHidden={isHidden}>
      {convertSecondsToMMSS(progress)}
      {" | "}
      {convertSecondsToMMSS(duration)}
    </MediaTimer>
  )
}

const MediaTimer = styled.div<{ isHidden: boolean | undefined }>`
  position: absolute;
  left: 0.5rem;
  bottom: 0rem;
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  line-height: 1.2;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-feature-settings: "tnum" 1, "zero" 1;
  pointer-events: none;
  opacity: ${({ isHidden }) => (isHidden ? "0" : "1")};
  transition: opacity 0.35s ease-in-out;

  @media ${({ theme }) => theme.mediaQueries.lg} {
    left: 1.5rem;
    bottom: 1.4rem;
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
    line-height: 1;
  }
`
