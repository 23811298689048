export interface ContentfulIframe {
  __typename: "ContentfulIframe"
  id: string
  url: string
  width: string
  height: string
  name: string
}

interface IframeProps {
  iframe: ContentfulIframe
}

export const Iframe = ({ iframe }: IframeProps): JSX.Element => {
  return (
    <iframe
      allowFullScreen
      src={iframe.url}
      title={iframe.name}
      style={{ border: "none", width: iframe.width, height: iframe.height }}
    />
  )
}
