import { IconButton, VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import { RichText } from "components/contentful-ui/RichText/RichText"
import { Paragraph } from "lib/ui/Typography/Typography"
import { useCallback, useState } from "react"
import type { ContentfulFAQItem } from "./faq"

const Wrapper = styled.div<{ hasTitle: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ hasTitle }) => (hasTitle ? "flex-start" : "flex-end")};
  flex: 1;
`
const Item = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary};
`
const Question = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 12px 0;
`

const Answer = styled.div`
  overflow: hidden;
  transform-origin: top;
  max-width: 90%;
`
const Spacing = styled.span`
  flex: 1;
`
interface QuestionsProps {
  items: ContentfulFAQItem[]
  hasTitle?: boolean
}
export const Questions = ({ items, hasTitle = true }: QuestionsProps): JSX.Element => {
  const [itemOpen, setItemOpen] = useState(new Array(items.length).fill(false))
  const toggleItem = useCallback(
    (index: number) => {
      const copy = [...itemOpen]
      copy[index] = !copy[index]
      setItemOpen(copy)
    },
    [itemOpen],
  )
  const isOpen = (index: number): boolean => itemOpen[index]
  return (
    <Wrapper hasTitle={hasTitle}>
      {items.map((item, index) => (
        <Item key={item.id} onClick={() => toggleItem(index)}>
          <Question>
            <Paragraph>{item.question}</Paragraph>
            <Spacing />
            <IconButton
              icon={isOpen(index) ? "arrowDown" : "arrowRight"}
              aria-label={
                isOpen(index)
                  ? "Show the answer to the question"
                  : "Close the answer to the question"
              }
            />
          </Question>
          {isOpen(index) && (
            <Answer>
              <RichText body={item.answer} />
              <VerticalSpacing size="md" />
            </Answer>
          )}
        </Item>
      ))}
    </Wrapper>
  )
}
