import { useEffect, useState } from "react"

export const useInViewport = (
  observeElement: React.RefObject<HTMLElement>,
  stopObserving: boolean,
): boolean | undefined => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.65,
    }

    const el = observeElement.current

    const callbackFunction = (entries: Array<IntersectionObserverEntry>): void => {
      const [entry] = entries
      if (isVisible !== entry.isIntersecting) {
        setIsVisible(entry.isIntersecting)
      }
    }

    const observer = new IntersectionObserver(callbackFunction, options)

    if (!stopObserving) {
      if (el) observer.observe(el)
    }

    return () => {
      if (el) observer.unobserve(el)
    }
  }, [observeElement, isVisible, stopObserving])

  return isVisible
}
