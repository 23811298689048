import { Title1, VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled/macro"
import { ContentfulImageCard, ImageCard } from "components/ui/ImageCard/ImageCard"
// import { Link } from "components/ui/Link/Link"
import { NewsCard } from "components/ui/NewsCard/NewsCard"
import { PersonCard } from "components/ui/PersonCard/PersonCard"
import { ContentfulInsight } from "components/views/InsightView/InsightView"
import { graphql, useStaticQuery } from "gatsby"
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import { breakpoint, cvars } from "lib/theming/breakpoint"
import { ThemeMode } from "types/common"
import { ContentfulCategory } from "types/contentful"
// import { Mapper } from "utils/componentMapper"
import { RichText } from "../RichText/RichText"

const Wrapper = styled.div`
  padding: ${cvars.sectionFrameSpacing};
`

const Columns = styled.div`
  @media ${({ theme }) => theme.mediaQueries.md} {
    display: grid;
    gap: ${cvars.gridGutter};
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
`

const Title = styled(Title1)`
  margin-top: ${({ theme }) => 3 * theme.spacer}px;
  margin-bottom: ${({ theme }) => 2 * theme.spacer}px;
`
const NewsGrid = styled.div`
  display: block;

  ${breakpoint.tablet} {
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(12, minmax(auto, auto));
  }
  ${breakpoint.desktop} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(12, minmax(auto, auto));
    margin-bottom: 24px;
  }
`

type ContentfulTopicListProps = {
  theme: ThemeMode
  component: ContentfulTopicList
}

interface QueryResult {
  allContentfulNews: {
    nodes: ContentfulInsight[]
  }
}

const makeCard = (
  item: ContentfulInsight,
  parentDisplay: TopicListDisplay,
  // alternateAspectRatio: boolean,
  index = 0,
): JSX.Element => {
  const data = {
    id: item.id,
    title: item.title,
    highlightsTitle: item.highlightsTitle,
    image: item.feedImage
      ? item.feedImage?.media.gatsbyImageData
      : item.featuredImage?.gatsbyImageData,
    date: item.publishDate,
    categories: item.categories,
    isNewsFeed: parentDisplay === "news grid",
    hideImageInFeed:
      item.hideImageInFeed !== undefined && item.hideImageInFeed !== null
        ? item.hideImageInFeed
        : !(item.feedImage || item.featuredImage),
    excerpt: item.excerpt,
    slug: item.slug,
  }

  if (
    item.categories &&
    item.categories.find((category: ContentfulCategory) =>
      category.name.toLowerCase().includes("speaker"),
    ) &&
    !data.isNewsFeed
  ) {
    return <PersonCard key={item.id} PersonCardItem={data} isPortrait={false} />
  }
  return (
    <NewsCard
      key={item.id}
      newsCardItem={data}
      isPortrait={index > 3 ? index % 2 === 0 : index % 2 !== 0}
    />
  )
}

function checkCategoriesInArrays(
  filterCategories: ContentfulCategory[],
  insightCategories: ContentfulCategory[],
): boolean {
  if (filterCategories && insightCategories) {
    const categoriesToFilterOn = filterCategories.map((obj: ContentfulCategory) => obj.name)
    const categoriesFromInsight = insightCategories.map((obj: ContentfulCategory) => obj.name)
    for (let i = 0; i < categoriesFromInsight.length; i += 1) {
      if (categoriesToFilterOn.includes(categoriesFromInsight[i])) {
        return true
      }
    }
    return false
  }
  return false
}

export const TopicList = ({ component, theme }: ContentfulTopicListProps): JSX.Element => {
  const { allContentfulNews } = useStaticQuery<QueryResult>(graphql`
    query AllInsightsItems {
      allContentfulNews(
        filter: { hidden: { ne: true } }
        sort: { fields: publishDate, order: DESC }
      ) {
        nodes {
          ...ContentfulNews
        }
      }
    }
  `)

  const items =
    component.filterMethod &&
    (component.filterMethod === "append" || component.filterMethod === "items-only")
      ? component.items.map((item, index) => {
          if (item.__typename === "ContentfulCard") {
            return (
              <ImageCard
                key={component.id}
                media={item.media}
                title={item.title}
                description={item.description}
                themeMode={theme}
              />
            )
          }
          if (item.__typename === "ContentfulNews") {
            return makeCard(item, component.display, index)
          }
          return null
        })
      : []

  const itemsByCategory: ContentfulInsight[] =
    component.filterMethod &&
    (component.filterMethod === "append" || component.filterMethod === "filter-only")
      ? allContentfulNews.nodes.filter((insight: ContentfulInsight) => {
          return checkCategoriesInArrays(component.filterByCategory, insight.categories)
        })
      : []

  const filteredItemCards =
    component.filterMethod === "append" || component.filterMethod === "filter-only"
      ? itemsByCategory.map((insight: ContentfulInsight, index) => {
          return makeCard(insight, component.display, index)
        })
      : []

  return (
    <Wrapper>
      <Columns>
        <div>
          <Title as="h2">{component.titleLeft}</Title>
        </div>
        <div>
          <RichText body={component.titleRight} />
        </div>
      </Columns>
      <VerticalSpacing size="lg" />

      {component.display === "news grid" && (
        <NewsGrid>
          <>
            {component.filterMethod === "append" && (
              <>
                {items.length > component.numberOfItems ? (
                  items.slice(0, component.numberOfItems)
                ) : (
                  <>
                    {items} {filteredItemCards.slice(0, component.numberOfItems - items.length)}
                  </>
                )}
              </>
            )}
            {component.filterMethod === "items-only" && (
              <>{items.slice(0, component.numberOfItems)}</>
            )}
            {component.filterMethod === "filter-only" && (
              <>{filteredItemCards.slice(0, component.numberOfItems)}</>
            )}
          </>
        </NewsGrid>
      )}

      {component.display === "two columns" && (
        <Columns>
          <>
            {component.filterMethod === "append" && (
              <>
                {items.length > component.numberOfItems ? (
                  items.slice(0, component.numberOfItems)
                ) : (
                  <>
                    {items} {filteredItemCards.slice(0, component.numberOfItems - items.length)}
                  </>
                )}
              </>
            )}
            {component.filterMethod === "items-only" && (
              <>{items.slice(0, component.numberOfItems)}</>
            )}
            {component.filterMethod === "filter-only" && (
              <>{filteredItemCards.slice(0, component.numberOfItems)}</>
            )}
          </>
        </Columns>
      )}
    </Wrapper>
  )
}

export type TopicListDisplay = "news grid" | "two columns"

export interface ContentfulTopicList {
  __typename: "ContentfulTopicList"
  id: string
  titleLeft: string
  titleRight: RenderRichTextData<ContentfulRichTextGatsbyReference>
  items: ContentfulImageCard[] | ContentfulInsight[]
  numberOfItems: number
  filterByCategory: ContentfulCategory[]
  filterMethod: "items-only" | "append" | "filter-only"
  readMoreLabel: string
  display: TopicListDisplay
}
