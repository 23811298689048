import { useEffect, useRef } from "react"

export const useInterval = (callback: () => void, delay: number | null): void => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const callbackRef = useRef(() => {})

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    const fireCallback = (): void => {
      callbackRef.current()
    }

    let id: NodeJS.Timeout
    if (delay !== null) {
      id = setInterval(fireCallback, delay)
    }
    return () => clearInterval(id)
  }, [delay])
}
