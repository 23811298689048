import { Radio, TextInput, VerticalLayout } from "@einride/ui"
import styled from "@emotion/styled"
import * as Sentry from "@sentry/gatsby"
import { suite } from "components/fragments/SignUpForm/suite"
import { StyledWithIconButton } from "components/ui/StyledWithIconButton/StyledWithIconButton"

import { useSiteMetadata } from "hooks/useSiteMetadata"
import { Paragraph } from "lib/ui/Typography/Typography"
import { ChangeEvent, FormEvent, useState } from "react"
import { pushLeadFormData } from "utils/tracking"
import { SuiteResult } from "vest"
import { FormGdpr } from "../FormGdpr/FormGdpr"

const submitSignUpForm = async (url: string, form: unknown): Promise<void> => {
  const myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")

  const req = new Request(url, {
    method: "POST",
    body: JSON.stringify(form),
    headers: myHeaders,
    redirect: "follow",
  })
  const res = await fetch(req)
  if (!res.ok) {
    throw new Error(`statusText: ${res.statusText}, status: ${res.status}`)
  }
}

const Form = styled.form`
  width: 100%;
  display: block;
`

const StyledVerticalLayout = styled(VerticalLayout)`
  margin-bottom: ${({ theme }) => 2 * theme.spacer}px;
  align-items: stretch;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 0;
  }
`

const TextareaButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Asterisk = styled.span`
  color: ${({ theme }) => theme.colors.content.negative};
`

interface SignUpFormProps {
  leadSource?: string | undefined
}

export const SignUpForm = ({ leadSource = "Sign up form" }: SignUpFormProps): JSX.Element => {
  const { endpoint } = useSiteMetadata()
  const { signup } = endpoint

  const [fullName, setFullName] = useState("")
  const [company, setCompany] = useState("")
  const [email, setEmail] = useState("")
  const [companySize, setCompanySize] = useState("0")
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)
  const [errors, setErrors] = useState<SuiteResult>(() => suite.get())

  const clearForm = (): void => {
    setFullName("")
    setCompany("")
    setEmail("")
    setCompanySize("")
  }

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()

    const suiteRes = suite({ fullName, company, email })
    setErrors(suiteRes)
    if (suiteRes.hasErrors()) {
      pushLeadFormData("lead_form_submission_failed", { company }, leadSource)
      return
    }

    const gclid = localStorage.getItem("gclid")
    const gclidValue = gclid ? JSON.parse(gclid).value : null
    const form = {
      full_name: fullName,
      company,
      email,
      company_size: companySize,
      lead_source: leadSource,
      gclid: gclidValue,
    }

    try {
      await submitSignUpForm(`${process.env.GATSBY_API_HOST}${signup}`, form)
      setSubmitted(true)
      setError(false)
      pushLeadFormData("submit_lead_form", form, leadSource)
      clearForm()
    } catch (err) {
      setSubmitted(false)
      setError(true)
      pushLeadFormData("lead_form_submission_failed", form, leadSource)
      Sentry.captureException(err)
    }
  }

  const companySizeOptions = {
    "1to50": "1-50",
    "51to200": "51-200",
    "201to1000": "201-1000",
    "1000plus": "1000+",
  }

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <StyledVerticalLayout>
        <TextInput
          label={
            <>
              Full name <Asterisk>*</Asterisk>
            </>
          }
          message={errors.getErrors("fullName")[0]}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFullName(e.target.value)
          }}
          placeholder="Full name"
          status={errors.hasErrors("fullName") ? "fail" : "neutral"}
          type="text"
          value={fullName}
        />
        <TextInput
          label={
            <>
              Company <Asterisk>*</Asterisk>
            </>
          }
          message={errors.getErrors("company")[0]}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setCompany(e.target.value)
          }}
          placeholder="Company"
          status={errors.hasErrors("company") ? "fail" : "neutral"}
          type="text"
          value={company}
        />

        <TextInput
          label={
            <>
              Work email <Asterisk>*</Asterisk>
            </>
          }
          message={errors.getErrors("email")[0]}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value)
          }}
          placeholder="Work email"
          status={errors.hasErrors("email") ? "fail" : "neutral"}
          type="email"
          value={email}
        />

        <RadioGroup>
          <RadioGroupLegend>Company size</RadioGroupLegend>
          {Object.keys(companySizeOptions).map((radioOption: string) => (
            <StyledRadio
              key={radioOption}
              name="companySize"
              value={radioOption}
              onChange={() => {
                setCompanySize(radioOption)
              }}
            >
              {companySizeOptions[radioOption as string as keyof typeof companySizeOptions]}
            </StyledRadio>
          ))}
        </RadioGroup>

        <TextareaButtonWrapper>
          <FormGdpr />
          {submitted ? (
            <Paragraph>Submitted!</Paragraph>
          ) : (
            <StyledWithIconButton>Submit</StyledWithIconButton>
          )}
          {error && <Paragraph>Unfortunately, the sign up form could not be submitted.</Paragraph>}
        </TextareaButtonWrapper>
      </StyledVerticalLayout>
    </Form>
  )
}

const RadioGroup = styled.fieldset`
  display: flex;
  flex-wrap: wrap;
  font-feature-settings: "zero";
  font-variant-numeric: slashed-zero;
  -webkit-font-feature-settings: "zero" 1 !important;
  > div {
    width: 100%;
    max-width: 50%;
    flex: 1 1 50%;

    @media ${({ theme }) => theme.mediaQueries.lg} {
      width: auto;
      max-width: none;
      flex: initial;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.lg} {
    justify-content: space-between;
  }
`

const RadioGroupLegend = styled.legend`
  width: 100%;

  ${({ theme }) => `color: ${theme.colors.content.secondary};`}
`

const StyledRadio = styled(Radio)``
