import { Carousel } from "components/contentful-ui/Carousel/Carousel"
import { Contact } from "components/contentful-ui/Contact/Contact"
import { Content } from "components/contentful-ui/Content/Content"
import { CtaBlock } from "components/contentful-ui/CtaBlock/CtaBlock"
import { FAQ } from "components/contentful-ui/FAQ/faq"
import { FooterLink } from "components/contentful-ui/FooterLink/FooterLink"
import { DocumentDownloadForm } from "components/contentful-ui/Form/DocumentDownload"
import { Form } from "components/contentful-ui/Form/Form"
import { Hero } from "components/contentful-ui/Hero/Hero"
import { Iframe } from "components/contentful-ui/Iframe/Iframe"
import { Intro } from "components/contentful-ui/Intro/Intro"
import { IntroFlexibleHeight } from "components/contentful-ui/IntroFlexibleHeight/IntroFlexibleHeight"
import { Logo } from "components/contentful-ui/Logo/Logo"
import { SegmentsSection } from "components/contentful-ui/SegmentsSection/SegmentsSection"
import { Taxonomy } from "components/contentful-ui/Taxonomy/Taxonomy"
import { Text } from "components/contentful-ui/Text/Text"
import { TopicList } from "components/contentful-ui/TopicList/TopicList"
import { TwoColumnContent } from "components/contentful-ui/TwoColumnContent/TwoColumnContent"
import { Video } from "components/contentful-ui/Video/Video"
import { ImageCard } from "components/ui/ImageCard/ImageCard"

import { RowsBlock } from "components/ui/RowsBlock/RowsBlock"
import { ReactNode } from "react"
import { ThemeMode } from "types/common"
import { PageComponents } from "types/contentful"

export const Mapper = (
  components: PageComponents[] | undefined,
  theme: ThemeMode,
  asCard = false,
): ReactNode => {
  if (components) {
    // eslint-disable-next-line react/destructuring-assignment
    return components.map((component: PageComponents) => {
      if (component.__typename === "ContentfulContent")
        return <Content key={component.id} content={component} />
      if (component.__typename === "ContentfulFooterLink")
        return <FooterLink key={component.id} footerLink={component} />
      if (component.__typename === "ContentfulContact")
        return <Contact contact={component} key={component.id} />
      if (component.__typename === "ContentfulCtaBlock")
        return <CtaBlock content={component} key={component.id} />
      if (component.__typename === "ContentfulHero")
        return <Hero key={component.id} hero={component} />
      if (component.__typename === "ContentfulIntro")
        return <Intro key={component.id} intro={component} />
      if (component.__typename === "ContentfulIntroFlexibleHeight")
        return <IntroFlexibleHeight key={component.id} intro={component} />
      if (component.__typename === "ContentfulFaq")
        return <FAQ key={component.id} faq={component} />
      if (component.__typename === "ContentfulSegments")
        return <SegmentsSection key={component.id} segmentsSection={component} />
      if (component.__typename === "ContentfulText")
        return <Text key={component.id} text={component} />
      if (component.__typename === "ContentfulTaxonomy")
        return <Taxonomy key={component.id} taxonomy={component} />
      if (component.__typename === "ContentfulCarousel")
        return <Carousel key={component.id} carousel={component} sectionTheme={theme} />
      if (component.__typename === "ContentfulVideo")
        return <Video key={component.id} video={component} />
      if (component.__typename === "ContentfulForm")
        return component.formOverride === "Default" ? (
          <Form key={component.id} form={component} />
        ) : (
          <DocumentDownloadForm key={component.id} form={component} />
        )
      if (component.__typename === "ContentfulTwoColumnContent")
        return (
          <TwoColumnContent
            key={component.id}
            theme={theme}
            leftColumn={component.leftColumn}
            rightColumn={component.rightColumn}
          />
        )

      if (component.__typename === "ContentfulRowsBlock")
        return <RowsBlock key={component.id} component={component} themeMode={theme} />
      if (component.__typename === "ContentfulTopicList")
        return <TopicList key={component.id} component={component} theme={theme} />
      if (component.__typename === "ContentfulCard")
        return (
          <ImageCard
            key={component.id}
            media={component.media}
            title={component.title}
            description={component.description}
            themeMode={theme}
          />
        )
      if (component.__typename === "ContentfulNews" && asCard)
        return (
          <ImageCard
            key={component.id}
            media={component.feedImage.media.file.url}
            title={component.title}
            description={component.excerpt.excerpt}
            themeMode={theme}
          />
        )
      if (component.__typename === "ContentfulIframe")
        return <Iframe iframe={component} key={component.id} />
      if (component.__typename === "ContentfulLogo")
        return <Logo content={component} key={component.id} />

      return null
    })
  }
  return null
}
