import { Section } from "components/contentful-ui/Section/Section"

import { Layout } from "components/ui/Layout/Layout"
import { Script } from "gatsby"
import { ThemeMode } from "types/common"

import { PageComponents } from "types/contentful"
import { Mapper } from "utils/componentMapper"

export type PageSection = {
  components?: PageComponents[]
  id: string
  theme?: ThemeMode
}
interface PageViewProps {
  page: {
    sections?: PageSection[]
  }
  addCookie: boolean
}

export const PageView = ({ page, addCookie }: PageViewProps): JSX.Element => {
  return (
    <Layout>
      {page.sections?.map((section) => (
        <Section key={section.id} theme={section.theme}>
          {Mapper(section.components, section.theme)}
        </Section>
      ))}
      {addCookie && (
        <Script
          id="CookieDeclaration"
          src={`https://consent.cookiebot.com/${process.env.GATSBY_COOKIEBOT_ID}/cd.js`}
          type="text/javascript"
          strategy="idle"
          async
        />
      )}
    </Layout>
  )
}
