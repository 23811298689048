import { useCallback, useEffect, useState } from "react"
import { pushCarouselData } from "utils/tracking"
import { type ContentfulCarouselItem } from "./Carousel"

interface CarouselType {
  currentIndex: number
  changeCarouselIndex: (counter: number) => void
  handleChangeSpecificIndex: (index: number) => void
}

export const useCarousel = (
  carouselItems: ContentfulCarouselItem[],
  carouselName: string,
  autoplay?: boolean,
  autoplayInterval?: number,
): CarouselType => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const changeCarouselIndex = useCallback(
    async (counter: number): Promise<void> => {
      const setIndex = async (): Promise<number> => {
        let newIndex = currentIndex
        if (currentIndex + counter < 0) {
          newIndex = carouselItems.length - 1
        } else if (currentIndex + counter >= carouselItems.length) {
          newIndex = 0
        } else {
          newIndex = currentIndex + counter
        }
        setCurrentIndex(newIndex)
        return newIndex
      }

      const newIndex = await setIndex()

      pushCarouselData("carousel_navigate_arrows", carouselName, newIndex)
    },
    [currentIndex, carouselItems.length, carouselName],
  )

  const handleChangeSpecificIndex = async (index: number): Promise<void> => {
    const setIndex = async (): Promise<number> => {
      setCurrentIndex(index)
      return index
    }

    const newIndex = await setIndex()
    pushCarouselData("carousel_navigate_titles", carouselName, newIndex)
  }

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (autoplay && autoplayInterval) {
      interval = setInterval(() => {
        changeCarouselIndex(1)
      }, autoplayInterval * 1000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [currentIndex, autoplayInterval, autoplay, changeCarouselIndex])

  return {
    changeCarouselIndex,
    handleChangeSpecificIndex,
    currentIndex,
  }
}
