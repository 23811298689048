/* eslint-disable no-unused-expressions */
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"
import { useVideoPlayback } from "hooks/useVideoPlayback"
import { useEffect } from "react"
import { ContentfulMedia } from "types/contentful"

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

const Video = styled.video`
  width: 100%;
  height: 100%;
`

export type ObjectFit = "contain" | "cover"

interface VideoParams {
  currentTime: number
  totalDuration: number
  isPlaying: boolean
  hasStarted: boolean
  duration: number
}

interface MediaProps {
  className?: string
  media: ContentfulMedia | undefined
  objectFit?: ObjectFit | undefined
  handleVideoParamChanges?: (videoParams: VideoParams) => VideoParams | undefined
}

export const Media = ({
  className,
  media,
  objectFit = "cover",
  handleVideoParamChanges,
}: MediaProps): JSX.Element | null => {
  const [videoRef] = useVideoPlayback()

  const videoParams = {
    currentTime: 0,
    totalDuration: 0,
    isPlaying: false,
    hasStarted: false,
    duration: 0,
  }

  useEffect(() => {
    const videoEl = videoRef.current

    if (!videoEl) {
      return undefined
    }

    const handleCurrentTime = (e): void => {
      handleVideoParamChanges &&
        handleVideoParamChanges({
          ...videoParams,
          ...{
            currentTime: parseInt(e.currentTarget.currentTime, 10),
            isPlaying: true,
          },
        })
    }

    const updateIsPlaying = (): void => {
      handleVideoParamChanges &&
        handleVideoParamChanges({
          ...videoParams,
          ...{ isPlaying: false },
        })
    }

    const handlePlay = (): void => {
      videoParams.hasStarted = true
      videoParams.duration = parseInt(videoEl.duration, 10)
      handleVideoParamChanges &&
        handleVideoParamChanges({
          ...videoParams,
          ...{ isPlaying: true },
        })
    }

    if (handleVideoParamChanges !== undefined) {
      videoEl.addEventListener("timeupdate", handleCurrentTime)

      videoEl.addEventListener("play", handlePlay)

      videoEl.addEventListener("pause", updateIsPlaying)

      videoEl.addEventListener("ended", updateIsPlaying)
    }

    return () => {
      videoEl.removeEventListener("timeupdate", handleCurrentTime)
      videoEl.removeEventListener("play", handlePlay)
      videoEl.removeEventListener("pause", updateIsPlaying)
      videoEl.removeEventListener("ended", updateIsPlaying)
    }
  })

  if (!media || !media.media) return null

  if (media.__typename === "ContentfulImage") {
    return (
      <Image
        alt={media.altText}
        className={className ?? ""}
        image={media.media.gatsbyImageData}
        objectFit={objectFit}
      />
    )
  }

  const isBrowser = typeof window !== undefined && typeof window !== "undefined"
  let videoSourceUrl = media.media.file.url
  let posterSourceUrl = media.videoPreviewImage?.file.url

  if (isBrowser && window.innerWidth < 600) {
    if (media.mobileMedia) {
      videoSourceUrl = media.mobileMedia.file.url ?? media.media.file.url
    }

    if (media.mobileVideoPreviewImage) {
      posterSourceUrl = media.mobileVideoPreviewImage?.file.url ?? media.videoPreviewImage?.file.url
    }
  }

  return (
    <Video
      ref={videoRef}
      className={className}
      autoPlay={media.isAutoPlaying}
      controls={media.showControls}
      controlsList="nodownload"
      crossOrigin="anonymous"
      disablePictureInPicture
      loop={media.isLooping}
      muted={media.isAutoPlaying || media.isMuted}
      playsInline
      preload="auto"
      poster={posterSourceUrl}
      onContextMenu={(e) => e.preventDefault()}
    >
      <source key="h264" src={videoSourceUrl} type="video/mp4" />
    </Video>
  )
}
