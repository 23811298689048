/* eslint-disable no-nested-ternary */
import { Paragraph } from "@einride/ui"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Title2 } from "lib/ui/Typography/Typography"

interface Category {
  name: string
}

interface PersonCardProps {
  PersonCardItem: {
    id: string
    title?: string
    image?: IGatsbyImageData
    date?: string
    highlightsTitle: string
    categories: Category[]
    hideImageInFeed?: boolean | undefined
    isNewsFeed: boolean
    excerpt: {
      excerpt: string
    }
    slug: string
  }
  isPortrait: boolean
}

export const PersonCard = ({ PersonCardItem, isPortrait }: PersonCardProps): JSX.Element => {
  const { title, image, hideImageInFeed, excerpt, slug, isNewsFeed, highlightsTitle } =
    PersonCardItem
  return (
    <Holder>
      <>
        {image && !hideImageInFeed && (
          <Link to={`/${process.env.GATSBY_SITE_INSIGHTS_URL}/${slug}`}>
            <GatsbyImage
              image={image}
              alt={title || ""}
              style={{
                aspectRatio: isPortrait ? "calc(330/396)" : "calc(330/248)",
              }}
            />
          </Link>
        )}
        {isNewsFeed ? (
          highlightsTitle ? (
            <HighlightsTitle>{highlightsTitle}</HighlightsTitle>
          ) : (
            <Title>{title}</Title>
          )
        ) : undefined}
        {!isNewsFeed && <Title>{title}</Title>}

        {!isNewsFeed && (
          <>
            <Excerpt>{excerpt.excerpt}</Excerpt>
            <CtaLink to={`/${process.env.GATSBY_SITE_INSIGHTS_URL}/${slug}`}>Read more</CtaLink>
          </>
        )}
      </>
    </Holder>
  )
}

const Holder = styled.div`
  padding-bottom: 30px;
`

const Title = styled(Paragraph)`
  margin-top: ${({ theme }) => 2 * theme.spacer}px;
`
const HighlightsTitle = styled(Title2)`
  margin-top: 10px;
  margin-bottom: 6px;
`

const Excerpt = styled(Paragraph)`
  color: rgba(30, 30, 31, 0.6);
  margin-top: ${({ theme }) => theme.spacer}px;
`

const CtaLink = styled(Link)`
  margin-top: ${({ theme }) => 2 * theme.spacer}px;
  display: block;
  color: #0d9d01;
  text-decoration: underline;
`
