// video.tsx
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"
import { useInViewport } from "hooks/useInViewport"
import { useVideoPlayer } from "hooks/useVideoPlayer"
import { cvars, mediaQueries } from "lib/theming/breakpoint"
import { useEffect, useRef, useState } from "react"
import { ContentfulVideo } from "types/contentful"
import { Timer } from "./Timer"

interface Props {
  video: ContentfulVideo
}

export const Video = ({ video }: Props): JSX.Element => {
  const videoElement = useRef<HTMLVideoElement>(null)

  const [videoSource, setVideoSource] = useState("")
  const [showTimer, setShowTimer] = useState(false)

  const {
    hasStarted,
    isPlaying,
    isCompleted,
    progress,
    togglePlay,
    handleVideoProgress,
    handleOnCompleted,
  } = useVideoPlayer(videoElement)

  const isVisible = useInViewport(videoElement, hasStarted || !video.isAutoPlaying)

  if (isVisible && !hasStarted && video.isAutoPlaying) {
    togglePlay()
  }

  let timerFade: NodeJS.Timeout

  const handleMediaMouseMove = (): void => {
    clearTimeout(timerFade)
    setShowTimer(true)
    timerFade = setTimeout(() => setShowTimer(false), 3000)
  }

  useEffect(() => {
    setVideoSource(video.mobileMedia?.file.url ?? video.media?.file.url ?? "")

    const isBrowser = typeof window !== undefined && typeof window !== "undefined"
    if (isBrowser) {
      if (window.innerWidth < 600) {
        if (video.mobileMedia) {
          setVideoSource(video.mobileMedia.file.url)
        }
      } else if (video.media) {
        setVideoSource(video.media.file.url)
      }
    }
  }, [video.mobileMedia, video.media])

  return (
    <Wrapper>
      <MediaGrid>
        <MediaWrapper fullWidth={isPlaying} onMouseMove={handleMediaMouseMove}>
          <MediaContainer>
            <StyledVideo
              ref={videoElement}
              controls={video.showControls}
              controlsList="nodownload"
              crossOrigin="anonymous"
              disablePictureInPicture
              loop={video.isLooping}
              muted={video.isAutoPlaying || video.isMuted}
              playsInline
              preload="auto"
              onEnded={handleOnCompleted}
              src={videoSource}
            />
            <MediaPosterContainer isHidden={hasStarted}>
              {video.videoPreviewImage && (
                <DesktopImage
                  image={video.videoPreviewImage.gatsbyImageData}
                  alt={video.videoPreviewImage.description}
                />
              )}
              {(video.mobileVideoPreviewImage || video.videoPreviewImage) && (
                <MobileImage
                  image={
                    video.mobileVideoPreviewImage
                      ? video.mobileVideoPreviewImage.gatsbyImageData
                      : video.videoPreviewImage.gatsbyImageData
                  }
                  alt={
                    video.mobileVideoPreviewImage
                      ? video.mobileVideoPreviewImage.description
                      : video.videoPreviewImage.description
                  }
                />
              )}
            </MediaPosterContainer>

            <MediaPlay onClick={togglePlay} isHidden={isPlaying && !isCompleted}>
              {isCompleted && "Play"}

              {!isCompleted && (isPlaying ? "Pause" : "Play")}
            </MediaPlay>

            {isPlaying && videoElement.current && (
              <Timer
                isHidden={!showTimer}
                active={isPlaying}
                duration={Math.floor(videoElement.current.duration)}
                startTime={Math.floor(videoElement.current.currentTime)}
              />
            )}

            <MediaScrubber
              type="range"
              min="0"
              max="100"
              value={progress || 0}
              onChange={(e) => handleVideoProgress(e)}
            />
          </MediaContainer>
        </MediaWrapper>
      </MediaGrid>
    </Wrapper>
  )
}

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  padding: ${cvars.sectionFrameSpacing};
`
const MediaGrid = styled.div`
  margin: ${({ theme }) => `${16 * theme.spacer}px 0`};
  color: white;
  @media ${({ theme }) => theme.mediaQueries.md} {
    position: relative;
    width: 100%;
    padding: 0;
    padding-top: calc(100% / 16 * 9);
    margin: ${({ theme }) => 12 * theme.spacer}px auto;
  }
`

const MediaWrapper = styled.div<{ fullWidth: boolean | undefined }>`
  position: relative;
  width: 100%;
  transition: width 0.4s cubic-bezier(0.84, 0, 0.33, 1);
  video[poster] {
    object-fit: cover;
  }
  font-variant-numeric: slashed-zero;

  @media ${({ theme }) => theme.mediaQueries.md} {
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${({ fullWidth }) => (fullWidth ? 100 : (100 / 12) * 8)}%;
    transform: translate(-50%, -50%);
  }

  @media (prefers-reduced-motion) {
    transition: none;
  }
`

const MediaContainer = styled.div`
  width: 100%;
`

const MediaScrubber = styled.input`
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  opacity: 0;
`

const MediaPlay = styled.button<{ isHidden: boolean | undefined }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: calc(6 / 5);
  background: #12121233;
  opacity: ${({ isHidden }) => (isHidden ? "0" : "1")};

  @media ${({ theme }) => theme.mediaQueries.lg} {
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
    line-height: 1;
  }
`

const MediaPosterContainer = styled.div<{ isHidden: boolean | undefined }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ isHidden }) => (isHidden ? "0" : "1")};
`

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

const DesktopImage = styled(Image)`
  display: none !important;

  @media ${mediaQueries.md} {
    display: block !important;
  }
`

const MobileImage = styled(Image)`
  @media ${mediaQueries.md} {
    display: none !important;
  }
`
