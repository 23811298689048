import { create, enforce, only, test } from "vest"

export const suite = create(
  (
    data: {
      fullName: string
      company: string
      title: string
      email: string
      message: string
    },
    fieldName?: string,
  ) => {
    only(fieldName)

    test("fullName", "Full name is required", () => {
      enforce(data.fullName).isNotBlank()
    })

    test("company", "Company is required", () => {
      enforce(data.company).isNotBlank()
    })

    test("title", "Title is required", () => {
      enforce(data.title).isNotBlank()
    })

    test("email", "Email is required", () => {
      enforce(data.email).isNotBlank()
    })

    test("email", "Email is not valid", () => {
      enforce(data.email).matches(/\S+@\S+\.\S+/)
    })

    test("email", ".edu addresses are not allowed", () => {
      enforce(data.email).doesNotEndWith(".edu")
    })
    test("email", "Gmail addresses are not allowed", () => {
      enforce("gmail").notInside(data.email)
    })

    test("message", "Message is required", () => {
      enforce(data.message).isNotBlank()
    })
    const messageError =
      "Message cannot contain student, job or education. For more information about potential jobs or internships, please visit our careers page."

    test("message", messageError, () => {
      enforce("student").notInside(data.message)
    })
    test("message", messageError, () => {
      enforce("job").notInside(data.message)
    })
    test("message", messageError, () => {
      enforce("education").notInside(data.message)
    })
  },
)
