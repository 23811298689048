import { Tabs, TabsContent, TabsList, TabsTrigger } from "@einride/ui"
import styled from "@emotion/styled"
import { SegmentContent } from "components/contentful-ui/SegmentsSection/components/SegmentContent/SegmentContent"
import { cvars } from "lib/theming/breakpoint"
import { Title1 } from "lib/ui/Typography/Typography"
import { ContentfulMedia, ContentfulRichText } from "types/contentful"

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.background.secondary};
  padding: ${cvars.sectionFrameSpacing};
  min-height: ${cvars.sectionHeight};
  display: flex;
  flex-direction: column;
`

const Spacer = styled.div`
  min-height: ${({ theme }) => 9 * theme.spacer}px;
`

export interface ContentfulSegment {
  media: ContentfulMedia
  primaryText: ContentfulRichText
  secondaryText?: ContentfulRichText
  title: string
}

export interface ContentfulSegments {
  __typename: "ContentfulSegments"
  id: string
  title: string
  segments: ContentfulSegment[]
}

interface SegmentsSectionProps {
  segmentsSection: ContentfulSegments
}

export const SegmentsSection = ({ segmentsSection }: SegmentsSectionProps): JSX.Element => {
  const segments = segmentsSection.segments.map((segment) => ({
    content: <SegmentContent segment={segment} />,
    id: segment.title,
    text: segment.title,
  }))
  return (
    <Wrapper>
      <Title1 as="h2">{segmentsSection.title}</Title1>
      <Spacer />
      <Tabs defaultValue={segments[0].id}>
        <TabsList grow>
          {segments.map((segment) => (
            <TabsTrigger key={segment.id} value={segment.id}>
              {segment.text}
            </TabsTrigger>
          ))}
        </TabsList>
        {segments.map((segment) => (
          <TabsContent key={segment.id} value={segment.id}>
            {segment.content}
          </TabsContent>
        ))}
      </Tabs>
    </Wrapper>
  )
}
