import { useEffect, useState } from "react"

const useCountdown = (targetDate: number, startDate: number): number[] => {
  const [timeLeft, setTimeLeft] = useState(
    new Date(startDate).getTime() - new Date(targetDate).getTime(),
  )

  let counterInterval = 1 * 24 * 60 * 60 * 1000

  if (Math.floor(targetDate / (1000 * 60 * 60 * 24)) < 2) {
    counterInterval = 1 * 44 * 24 * 1000
  }

  const totalDaysLeftInMs = new Date(startDate).getTime() - new Date(targetDate).getTime()

  const timerInterval = 750 / (totalDaysLeftInMs / counterInterval)

  const [countDown, setCountDown] = useState(new Date(startDate).getTime())

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (timeLeft < 0) {
      if (Math.floor(targetDate / (1000 * 60 * 60 * 24)) < 2) {
        setCountDown(targetDate)

        interval = setInterval(() => {
          setCountDown(targetDate - new Date().getTime())
        }, 60 * 1000)
      }

      return undefined
    }
    interval = setInterval(() => {
      setCountDown(targetDate + timeLeft)
      setTimeLeft(timeLeft - counterInterval)
    }, timerInterval)

    return () => clearInterval(interval)
  }, [counterInterval, targetDate, timeLeft, timerInterval])

  return getReturnValues(countDown)
}

const getReturnValues = (countDown: number): number[] => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))

  return [days, hours, minutes]
}

export { useCountdown }
