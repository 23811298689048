import { Icon, PrimaryButton, VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled/macro"
import {
  BackgroundColor,
  ContentfulContent,
  Layout,
} from "components/contentful-ui/Content/Content"
import { Media } from "components/contentful-ui/Media/Media"
import { RichText } from "components/contentful-ui/RichText/RichText"
import { Link } from "components/ui/Link/Link"
import { cvars } from "lib/theming/breakpoint"
import { Paragraph, Title1 } from "lib/ui/Typography/Typography"

export interface DesktopViewProps {
  className?: string
  content: ContentfulContent
}

export const DesktopView = ({ className, content }: DesktopViewProps): JSX.Element => {
  const {
    backgroundColor,
    backgroundMedia,
    foregroundMedia,
    foregroundMediaFit,
    layout,
    titleAlignment,
    link,
    linkStyle,
    text,
    title,
    secondaryTitle,
  } = content

  const contentTitles = (
    <TitleContainer isLeft={titleAlignment !== "Right aligned"}>
      <Title as="h2">{title}</Title>
      {secondaryTitle && <SubTitle as="h3">{secondaryTitle}</SubTitle>}
    </TitleContainer>
  )

  const renderLink = (): JSX.Element | undefined => {
    if (!link) {
      return undefined
    }
    if (linkStyle === "primary") {
      return (
        <Link to={link.url}>
          <CTAButton rightIcon={<Icon name="arrowRight" />}>{link.text}</CTAButton>
        </Link>
      )
    }
    return (
      <Link to={link.url}>
        <LinkText>{link.text}</LinkText>
      </Link>
    )
  }

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      className={className}
      layout={layout}
      hasForegroundMedia={foregroundMedia !== null}
    >
      <BackgroundMedia media={backgroundMedia} />
      {title && <TitleWrapper>{contentTitles}</TitleWrapper>}

      <ContentWrapper>
        <Left layout={layout}>
          {text && <RichText body={text} />}
          {text && link && <VerticalSpacing />}
          {link && renderLink()}
        </Left>
        <Right layout={layout}>
          <>
            {foregroundMedia?.__typename === "ContentfulVideo" ? (
              <ForegroundVideo media={foregroundMedia} layout={layout} />
            ) : (
              <ForegroundImage media={foregroundMedia} objectFit={foregroundMediaFit} />
            )}
          </>
        </Right>
      </ContentWrapper>
    </Wrapper>
  )
}

const SECTION_HEIGHT = "94vh"

const BackgroundMedia = styled(Media)`
  grid-area: 1/1;
  object-fit: cover;
  @media ${({ theme }) => theme.mediaQueries.lg} {
    height: ${SECTION_HEIGHT};
  }
`

const Title = styled(Title1)``

const SubTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.content.secondary};
`

const TitleContainer = styled.div<{ isLeft: boolean }>`
  grid-column: ${({ isLeft }) => (isLeft ? "1/4" : "9/13")};
  grid-row: 1/2;
  padding: 0 ${cvars.sectionFrameSpacing};
  padding-bottom: 0;
  margin-left: ${({ isLeft }) => (isLeft ? "initial" : `calc(-1*${cvars.sectionFrameSpacing})`)};
`

const TitleWrapper = styled.div`
  position: absolute;
  top: ${cvars.sectionFrameSpacing};
  left: 0;
  right: ${cvars.sectionFrameSpacing};
  display: grid;
  gap: ${cvars.gridGutter};
  grid-template-columns: repeat(12, 1fr);
`

const ContentWrapper = styled.div`
  grid-area: 1/1;
  padding: ${cvars.sectionFrameSpacing};
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: ${cvars.gridGutter};
  z-index: 999;
`

const Left = styled.div<{ layout: Layout }>`
  grid-column: ${({ layout }) => (layout === "text left" ? "1/5" : "9/13")};
  grid-row: 1;
  margin-top: auto;
`

const Right = styled.div<{ layout: Layout }>`
  grid-column: ${({ layout }) => (layout === "text left" ? "7/13" : "1/7")};
  grid-row: 1;
  height: ${`calc(${SECTION_HEIGHT} - 2 * ${cvars.sectionFrameSpacing})`};
  display: flex;
  flex-direction: column;
`

const ForegroundVideo = styled(Media)<{ layout: Layout }>`
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  object-fit: contain;
  object-position: bottom ${({ layout }) => (layout === "text left" ? "right" : "left")};
`

const ForegroundImage = styled(Media)``

const LinkText = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.content.positive};
  text-decoration: underline;
  margin: 0;
`

const Wrapper = styled.div<{
  backgroundColor: BackgroundColor | undefined
  layout: string | undefined
  hasForegroundMedia: boolean | undefined
}>`
  ${({ backgroundColor, theme }) =>
    backgroundColor === "background secondary" &&
    `background: ${theme.colors.background.secondary}`};

  ${({ layout, hasForegroundMedia }) =>
    layout === "text right" &&
    hasForegroundMedia &&
    `
    
    ${ContentWrapper} {
        grid-area: 2/1;
      }
      ${TitleContainer} {
        position: relative;
        top: 0;
        left: 0;
        z-index: 3;
        padding: ${cvars.sectionFrameSpacing};
        padding-bottom: 0;
        }
      ${Title} {
        position: static;
      }`};
  ${({ layout, hasForegroundMedia }) =>
    layout === "text right" &&
    !hasForegroundMedia &&
    `
    ${BackgroundMedia} {
      z-index: 0;
    }
    ${TitleContainer} {
      z-index: 1;
      padding: ${cvars.sectionFrameSpacing};
      }
      `};
  ${({ layout, hasForegroundMedia }) =>
    layout === "text left" &&
    hasForegroundMedia &&
    `${TitleContainer} {
        position: absolute;
        top: 0;
        left: ${cvars.sectionFrameSpacing};
        padding: 0;
        width: 35%;
        grid-column: auto;
        ${Title} {
          position: static;
        }
      }
      `};
  ${({ layout }) =>
    layout === "text left" &&
    `
  ${Left} {
    padding-top: 80px;
  }
  ${TitleContainer} ${Title} {
    top: 0;
  }`};
  display: grid;
  position: relative;
`

const CTAButton = styled(PrimaryButton)`
  --einride-ui-span: 4;
  min-width: calc(
    (
        (100vw - calc(var(--einride-ui-grid-columns) + 1) * var(--einride-ui-grid-gap)) /
          var(--einride-ui-grid-columns) + var(--einride-ui-grid-gap)
      ) * var(--einride-ui-span) - var(--einride-ui-grid-gap) - var(--einride-ui-grid-gap)
  );

  @media ${({ theme }) => theme.mediaQueries.lg} {
    margin-top: 1rem;
    --einride-ui-span: 3;
    min-width: calc(
      (
          (100vw - calc(var(--einride-ui-grid-columns) + 1) * var(--einride-ui-grid-gap)) /
            var(--einride-ui-grid-columns) + var(--einride-ui-grid-gap)
        ) * var(--einride-ui-span) - var(--einride-ui-grid-gap)
    );
  }
`
