import { VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import { RichText } from "components/contentful-ui/RichText/RichText"

import { HideAbove } from "components/shared/styles/Layout"
import { Link } from "components/ui/Link/Link"
import { StyledWithIconButton } from "components/ui/StyledWithIconButton/StyledWithIconButton"
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import { cvars, mediaQueries } from "lib/theming/breakpoint"
import { Title1 } from "lib/ui/Typography/Typography"
import { ContentfulLink } from "types/contentful"
import { formMapper } from "utils/formMapper"

export interface ContentfulIntro {
  __typename: "ContentfulIntro"
  id: string
  leftTitlePart1: string
  leftTitlePart2: string

  link?: ContentfulLink
  rightTitle: string
  text: RenderRichTextData<ContentfulRichTextGatsbyReference>
  form?: string
  leadSource?: string
  setTextAsTitle: boolean
}

const Wrapper = styled.div`
  padding: ${cvars.sectionFrameSpacing};

  @media ${({ theme }) => theme.mediaQueries.md} {
    display: grid;
    gap: ${cvars.gridGutter};
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
`

const Left = styled.div``

const Title = styled(Title1)``

const Right = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;

  @media ${({ theme }) => theme.mediaQueries.md} {
    margin-top: unset;
    margin-bottom: ${({ theme }) => theme.spacer}px;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => 2 * theme.spacer}px;
  }
`

const ResponsiveSpacing = styled(VerticalSpacing)`
  height: 40px;
  @media ${mediaQueries.belowMd} {
    height: 24px;
  }
`

const TextWrapper = styled.div<{ setTextAsTitle: boolean }>`
  a {
    color: ${({ theme }) => theme.colors.content.positive};
  }
  margin-top: ${({ theme }) => -3 * theme.spacer}px;
  p,
  b {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
  b {
    display: block;
  }
  & > div > * + *,
  & > div > * {
    padding-top: ${({ theme }) => theme.fontSizes.lg};
  }
`

interface IntroProps {
  intro: ContentfulIntro
}
export const Intro = ({ intro }: IntroProps): JSX.Element => {
  return (
    <Wrapper>
      <Left>
        <Title>{intro.leftTitlePart1}</Title>
        <Title color="secondary">{intro.leftTitlePart2}</Title>
      </Left>
      <Right>
        <HideAbove breakpoint={599}>
          <VerticalSpacing size="sm" />
        </HideAbove>
        {intro.rightTitle && <Title1 as="h2">{intro.rightTitle}</Title1>}
        <div>
          {!intro.setTextAsTitle && (
            <>
              <VerticalSpacing size="lg" />
              <VerticalSpacing size="lg" />
              <VerticalSpacing size="lg" />
            </>
          )}
          <TextWrapper setTextAsTitle={intro.setTextAsTitle}>
            {intro.text && <RichText body={intro.text} />}
          </TextWrapper>
          {intro.form ? (
            <>
              <ResponsiveSpacing size="lg" />
              {formMapper(intro.form, intro.leadSource)}
            </>
          ) : (
            intro.link && (
              <Link to={intro.link.url} linkType="cta_click">
                <StyledWithIconButton>{intro.link.text}</StyledWithIconButton>
              </Link>
            )
          )}

          <VerticalSpacing size="xl" />
          <VerticalSpacing size="xl" />
        </div>
      </Right>
    </Wrapper>
  )
}
