import { ContactForm } from "components/fragments/ContactForm/ContactForm"
import { JoinSagaForm } from "components/fragments/JoinSagaForm/JoinSagaForm"
import { ShipWithUsForm } from "components/fragments/ShipWithUsForm/ShipWithUsForm"
import { SignUpForm } from "components/fragments/SignUpForm/SignUpForm"
import { FormTypes } from "enums/Form"

export const formMapper = (form: string, leadSource?: string): JSX.Element => {
  switch (form) {
    case FormTypes.SIGN_UP:
      return <SignUpForm leadSource={leadSource} />

    case FormTypes.CONTACT:
      return <ContactForm leadSource={leadSource} />

    case FormTypes.JOIN_SAGA:
      return <JoinSagaForm leadSource={leadSource} />

    case FormTypes.SHIP_WITH_US:
      return <ShipWithUsForm leadSource={leadSource} />

    default:
      return <p>{form}</p>
  }
}
