/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import { RichText } from "components/contentful-ui/RichText/RichText"
import { Desktop, Mobile } from "components/shared/styles/Layout"
import { Script } from "gatsby"
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import { cvars, mediaQueries } from "lib/theming/breakpoint"
import { Title1 } from "lib/ui/Typography/Typography"
import { ContentfulDocument } from "types/contentful"
import { getLatestPageData } from "utils/tracking"

export interface ContentfulForm {
  __typename: "ContentfulForm"
  id: string
  leftTitle: string
  rightTitle: string
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>
  formSubmitText: string
  mailchimpAudienceId: string
  formId?: string
  documentField: ContentfulDocument
  formOverride: "Document download" | "Default"
}

export const Wrapper = styled.div`
  padding: ${cvars.sectionFrameSpacing};
  @media ${mediaQueries.md} {
    display: grid;
    gap: ${cvars.gridGutter};
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
`

export const RichTextContainer = styled.div`
  h1:first-of-type,
  h2:first-of-type,
  h3:first-of-type {
    margin-top: 0;
  }
  h1 + h1,
  h2 + h2,
  h3 + h3 {
    margin-top: calc(1.5rem * (4 / 3));
  }
`

export const Left = styled.div``

export const Title = styled(Title1)``

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: ${({ theme }) => theme.spacer}px;
  @media ${mediaQueries.md} {
    margin-top: unset;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => 2 * theme.spacer}px;
  }

  @media ${({ theme }) => theme.mediaQueries.belowMd} {
    h1 {
      margin-bottom: ${({ theme }) => theme.spacer * 3}px;
    }
  }
`

interface FormProps {
  form: ContentfulForm
}

export const Form = ({ form }: FormProps): JSX.Element => {
  const isBrowser = typeof window !== undefined && typeof window !== "undefined"
  const leadDetails = {
    leadDetails: {
      topic: undefined,
      country: undefined,
      company: undefined,
      formType: "mailchimp form",
    },
    company_size: undefined,
    event: "submit_lead_form",
    pageData: getLatestPageData(),
    subdomain: process.env.GATSBY_SITE_CHANNEL?.replace("channel", "").toLowerCase(),
    page_url: isBrowser ? window.location.href : undefined,
  }

  const mailchimpScript = `
  <!-- Begin Mailchimp Signup Form -->
  <div id="mc_embed_shell">
  <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
<style type="text/css">
    #mc_embed_signup{false;clear:left;}
    /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
       We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
<form action="https://tech.us15.list-manage.com/subscribe/post?u=47f14a2d89fc2d94b2de99f61&amp;id=${
    form.mailchimpAudienceId
  }&amp;f_id=${
    form.formId || "00b793e0f0"
  }" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
    <div id="mc_embed_signup_scroll">
        <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
        <div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""></div><div class="mc-field-group"><label for="mce-COMPANY">Company <span class="asterisk">*</span></label><input type="text" name="COMPANY" class="required text" id="mce-COMPANY" required="" value=""></div>
    <div id="mce-responses" class="clear">
        <div class="response" id="mce-error-response" style="display: none;"></div>
        <div class="response" id="mce-success-response" style="display: none;"></div>
    </div><div aria-hidden="true" style="position: absolute; left: -5000px;"><input type="text" name="b_47f14a2d89fc2d94b2de99f61_${
      form.mailchimpAudienceId
    }" tabindex="-1" value=""></div>
    <p class="legal">
      By submitting this form, I acknowledge receipt of Einride&apos;s
      <a href="/privacy-policy">
        <span>Privacy Policy</span>
      </a>
      .
    </p>
    <div class="clear button-container">
    <input type="submit" name="subscribe" onClick="addMailchimpFormToDatalayer()" id="mc-embedded-subscribe" class="button" value="${
      form.formSubmitText ?? "Submit"
    }">
    </div>
</div>
</form>
</div>
<!--End mc_embed_signup-->
  `
  return (
    <Wrapper>
      <Left>
        {form.leftTitle && <a id={form.leftTitle.toLocaleLowerCase().replaceAll(" ", "-")} />}

        <Title>{form.leftTitle}</Title>
      </Left>
      <Right>
        <Mobile>
          <VerticalSpacing size="lg" />
          <VerticalSpacing size="lg" />
        </Mobile>
        <Title>{form.rightTitle}</Title>
        <Desktop>
          <VerticalSpacing size="xl" />
          <VerticalSpacing size="xl" />
        </Desktop>
        <div>
          {form.body && (
            <RichTextContainer>
              <RichText body={form.body} />
            </RichTextContainer>
          )}

          <Script id="addMailchimpFormToDatalayer">
            {`
              function addMailchimpFormToDatalayer(){
                const details = ${JSON.stringify(leadDetails)};
                const company = document.getElementById("mce-COMPANY").value;
                const email = document.getElementById("mce-EMAIL").value;
                if(company && email){
                  details.leadDetails.company = company;
                  window.dataLayer.push(details);
                }
              }  
            `}
          </Script>
          <FormContainer dangerouslySetInnerHTML={{ __html: mailchimpScript }} />

          <Script src="https://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js" />
        </div>
      </Right>
    </Wrapper>
  )
}

const HELPTEXT_HEIGHT = "6px"

export const FormContainer = styled.div`
  #mc_embed_signup {
    form {
      margin: 0;
      margin-top: 22px;

      @media ${({ theme }) => theme.mediaQueries.belowMd} {
        margin-top: 16px;
      }
    }
    h2 {
      margin-top: 6px;
      margin-bottom: 8px;
      font-size: ${({ theme }) => theme.fontSizes.lg};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      line-height: calc(8 / 7);
      font-variant-numeric: slashed-zero;
      color: ${({ theme }) => theme.colors.content.primary};
    }
    .indicates-required {
      display: none;
      margin-right: 0;
    }
    .mc-field-group {
      width: 100%;
      padding-bottom: calc(21px - ${HELPTEXT_HEIGHT});

      @media ${({ theme }) => theme.mediaQueries.belowMd} {
        padding-bottom: calc(8px - ${HELPTEXT_HEIGHT});
      }

      label {
        font-size: 1.125rem;
        font-weight: 450;
        line-height: calc(4 / 3);
        color: ${({ theme }) => theme.colors.content.secondary};
      }
      .asterisk {
        position: static;
        font-size: 1em;
        color: ${({ theme }) => theme.colors.content.negative};
      }
      input {
        background: #f5f5f5;
        color: #1e1e1f;
        width: 100%;
        display: block;
        padding-block: 12px;
        padding-left: 16px;
        padding-right: 48px;
        line-height: calc(4 / 3);
        font-weight: 450;
        text-indent: initial;
        border-radius: 0.5rem;
        cursor: pointer;
        border: none;
        appearance: none;
        :focus {
          box-shadow: 0px 0px 0px 1px #121212 inset;
          outline: none;
        }
        :hover:not(:disabled) {
          background: #ebebeb;
        }
      }
    }
    .button {
      -webkit-appearance: none;
      margin: 0;
      height: 48px;
      font-size: 1.125rem;
      border-radius: 1.5rem;
      padding: 0 16px;
      padding-right: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-width: 88px;
      text-align: left;
      cursor: pointer;
      background: ${({ theme }) => theme.colors.buttons.background.primary};
      color: ${({ theme }) => theme.colors.buttons.text.primary};
      :hover,
      :focus {
        background: ${({ theme }) => theme.colors.buttons.background.hover.primary};
      }
      :active {
        background: ${({ theme }) => theme.colors.buttons.background.active.primary};
      }
      :disabled {
        background: ${({ theme }) => theme.colors.buttons.background.disabled.primary};
      }
      @media ${({ theme }) => theme.mediaQueries.md} {
        width: 100%;
      }
    }

    #mce-responses {
      float: none;
      margin: 0;
      padding: 0;
      width: auto;

      div.response {
        position: static;
        float: none;
        width: auto;
        margin: 0;
        margin-top: ${({ theme }) => 3 * theme.spacer}px;
        padding: 0;
        font-weight: ${({ theme }) => theme.fontWeights.book};
      }
    }

    #mce-success-response {
      color: ${({ theme }) => theme.colors.content.positive};
    }

    #mce-error-response {
      color: ${({ theme }) => theme.colors.content.negative};
    }

    .button-container {
      position: relative;
      margin-top: ${({ theme }) => 3 * theme.spacer}px;

      ::after {
        position: absolute;
        top: 50%;
        right: ${({ theme }) => 2 * theme.spacer}px;
        content: "→";
        color: ${({ theme }) => theme.colors.buttons.icon.primary};
        transform: translateY(-50%);
      }

      @media ${mediaQueries.md} {
        max-width: 330px;
        margin-top: ${({ theme }) => 2 * theme.spacer}px;
      }
    }

    .legal {
      margin-top: calc(24px - 8px);
      color: ${({ theme }) => theme.colors.content.secondary};

      span {
        color: ${({ theme }) => theme.colors.content.positive};
      }

      @media ${mediaQueries.md} {
        margin-top: calc(48px - 21px);
      }
    }
  }
`
