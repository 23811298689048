import styled from "@emotion/styled"
import { ContentWrapper } from "components/shared/styles/Layout"
import { mediaQueries } from "lib/theming/breakpoint"
import { ThemeMode } from "types/common"
import { ContentfulKPI, KPI } from "../KPI/KPI"

const RowWrapper = styled(ContentWrapper)`
  display: flex;
  padding: var(--section-frame-spacing) 0 0 0;
`

const ItemsWrapper = styled.div<{ numberOfItems: number }>`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  column-gap: ${({ theme }) => 3 * theme.spacer}px;
  row-gap: ${({ theme }) => 3 * theme.spacer}px;
  color: ${({ theme }) => theme.colors.content.primary};
  > * {
    flex: 1;
    @media ${mediaQueries.belowMd} {
      flex-basis: ${({ numberOfItems }) => (numberOfItems === 4 ? "48%" : "30%")};
    }
    @media ${mediaQueries.belowSm} {
      flex-basis: 100%;
    }
  }
`

export interface ContentfulKpiRow {
  __typename: "ContentfulKpiRow"
  id: string
  items: ContentfulKPI[]
}

interface RowProps {
  component: ContentfulKpiRow
  themeMode?: ThemeMode
}

export const KPIRow = ({ component, themeMode = "light" }: RowProps): JSX.Element => {
  const KPIs = component.items.map((kpi: ContentfulKPI) => (
    <KPI
      bigText={kpi.bigText}
      key={kpi.id}
      intro={kpi.intro}
      content={kpi.content}
      themeMode={themeMode}
    />
  ))
  return (
    <RowWrapper>
      <ItemsWrapper numberOfItems={component.items.length}>{KPIs}</ItemsWrapper>
    </RowWrapper>
  )
}
