import { VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import { Media } from "components/contentful-ui/Media/Media"
import { RichText } from "components/contentful-ui/RichText/RichText"
import { ContentfulSegment } from "components/contentful-ui/SegmentsSection/SegmentsSection"

const FlexWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
`

const Wrapper = styled.div`
  @media ${({ theme }) => theme.mediaQueries.lg} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${({ theme }) => 3 * theme.spacer}px;
    width: 100%;
  }
`

const Left = styled.div`
  margin-bottom: ${({ theme }) => 5 * theme.spacer}px;

  @media ${({ theme }) => theme.mediaQueries.lg} {
    margin-bottom: 0;
    margin-top: auto;
    width: calc(2 * 100% / 3);
  }
`

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledMedia = styled(Media)`
  aspect-ratio: 1;
`

interface SegmentsContentProps {
  segment: ContentfulSegment
}

export const SegmentContent = ({ segment }: SegmentsContentProps): JSX.Element => {
  return (
    <FlexWrapper>
      <Wrapper>
        <Left>
          <RichText body={segment.primaryText} />
          {segment.secondaryText && (
            <>
              <VerticalSpacing size="md" />
              <RichText body={segment.secondaryText} />
            </>
          )}
        </Left>
        <Right>
          <StyledMedia media={segment.media} />
        </Right>
      </Wrapper>
    </FlexWrapper>
  )
}
