import { Title3, VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { cvars, mediaQueries } from "lib/theming/breakpoint"
import { Title1 } from "lib/ui/Typography/Typography"
import { ContentfulRichText } from "types/contentful"
import { RichText } from "../RichText/RichText"

export interface ContentfulLogo {
  __typename: "ContentfulLogo"
  id: string
  name: string
  title: string
  subtitle: string
  layout: "text left" | "text right"
  text: ContentfulRichText
  logos: {
    id: string
    media: {
      gatsbyImageData: IGatsbyImageData
      file: {
        contentType: string
        url: string
        details: {
          image: {
            width: number
            height: number
          }
        }
      }
    }
    altText: string
  }[]
}

interface Props {
  content: ContentfulLogo
}

export const Logo = ({ content }: Props): JSX.Element => {
  return (
    <Wrapper>
      <VerticalSpacing size="lg" />
      {!content.text && (
        <WrapperRow>
          <WrapperCol>
            <Title1 as="h2">{content.title}</Title1>
            {content.subtitle && <Subtitle>{content.subtitle}</Subtitle>}
            <VerticalSpacing size="sm" />
          </WrapperCol>
        </WrapperRow>
      )}
      <WrapperRow>
        {content.text && (
          <WrapperCol>
            <Title1 as="h2">{content.title}</Title1>
            {content.subtitle && <Subtitle>{content.subtitle}</Subtitle>}
            <VerticalSpacing size="sm" />
            <RichText body={content.text} />
          </WrapperCol>
        )}
        <WrapperCol>
          <VerticalSpacing size="lg" />
          {content.logos && content.logos.length && (
            <LogoList logoCount={content.logos.length}>
              {content.logos.map((logo) => (
                <LogoListItem
                  key={logo.id}
                  logoCount={content.logos.length}
                  isColumns={!!content.text}
                >
                  {logo.media.file.contentType === "image/svg+xml" ? (
                    <SVGContainer>
                      <img
                        src={logo.media.file.url}
                        alt={logo.altText}
                        width={logo.media.file.details.image.width}
                        height={logo.media.file.details.image.height}
                      />
                    </SVGContainer>
                  ) : (
                    <StyledImage image={logo.media.gatsbyImageData} alt={logo.altText} />
                  )}
                </LogoListItem>
              ))}
            </LogoList>
          )}
        </WrapperCol>
      </WrapperRow>
      <VerticalSpacing size="lg" />
      <VerticalSpacing size="lg" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: ${cvars.sectionFrameSpacing};
  font-feature-settings: "zero";
  font-variant-numeric: slashed-zero;
  -webkit-font-feature-settings: "zero" 1 !important;
`

const WrapperRow = styled.div`
  @media ${mediaQueries.lg} {
    display: flex;
    margin: 0 calc((${cvars.sectionFrameSpacing} / 2) * -1);
  }
`

const WrapperCol = styled.div`
  @media ${mediaQueries.lg} {
    flex: 1 1 0;
    padding: 0 calc(${cvars.sectionFrameSpacing} / 2);
  }
`

const SVGContainer = styled.div`
  max-width: 137px;
  img {
    width: auto;
    height: 80px;
  }
`

const StyledImage = styled(GatsbyImage)`
  max-width: 137px;
`

const LogoList = styled.ul<{ logoCount: number }>`
  display: grid;
  list-style: none;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${({ theme }) => 3 * theme.spacer}px;
  row-gap: ${({ theme }) => 3 * theme.spacer}px;

  @media ${mediaQueries.sm} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: ${({ theme }) => 6 * theme.spacer}px;
  }

  @media ${mediaQueries.lg} {
    row-gap: ${({ theme }) => 8 * theme.spacer}px;
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
`

const LogoListItem = styled.li<{ logoCount: number; isColumns: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${StyledImage},
  ${SVGContainer} {
    max-height: 80px;
  }

  @media ${mediaQueries.belowSm} {
    justify-content: flex-start;
    :nth-child(even) {
      justify-content: flex-end;
    }
  }

  ${({ logoCount }) => `
  @media ${mediaQueries.sm} {
    justify-content: flex-start;

    ${StyledImage},
    ${SVGContainer} {
      max-width: 233px;
      justify-self: flex-start;
    }

    ${
      logoCount > 2 &&
      `
      ${StyledImage},
      ${SVGContainer} {
        max-width: 188px;
      }
      `
    }    
  }
  `}

  ${({ logoCount, isColumns }) => `
  @media ${mediaQueries.lg} {
    ${logoCount > 2 && "width: 35%;"}

    ${
      logoCount > 4 &&
      `
      ${!isColumns && "width: auto"};
      flex: ${isColumns ? "auto" : 0};
      
      ${StyledImage},
      ${SVGContainer} {
        max-width: 142px;
      }`
    }
  }
  `}
`

const Subtitle = styled(Title3)`
  color: ${({ theme }) => theme.colors.content.secondary};
`
